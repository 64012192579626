// @ts-nocheck
// @ts-ignore
import { combineEpics } from 'redux-observable';

// Here we need the Epics from the Ducks
import { epics as backendEpics } from './ducks/backendDuck';
import { epics as userEpics } from './ducks/userDuck';
import { epics as uiEpics } from './ducks/uiDuck';

// the epics are combined to
export const rootEpic: Epic<Action<any>, Action<any>, RootState, any> = combineEpics(
  backendEpics.identifyActionEpic,
  backendEpics.operateNeoEpic,
  backendEpics.deleteNeoEpic,
  backendEpics.crudProcessdataEpic,
  backendEpics.distinctRolesEpic,
  uiEpics.deleteDataEpic,
  uiEpics.updateWorkspaceEpic,
  uiEpics.loadConfigEpic,
  userEpics.workspace,
  userEpics.security,
);
