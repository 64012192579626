import { Dispatch } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import * as uiDuck from './ducks/uiDuck';
import _ from 'lodash';

//////
/////
////
/// This File merely exists because if the original is taken from graphHelper
//  the uiduck reducer fails to create
//

// retrieves an array of definitions
// returns a single definition which contains all common properties of that array
const getCommonProperties = (
    aggregate: cytoscape.ElementDataDefinition,
    inputArray: cytoscape.ElementDataDefinition[],
  ): cytoscape.ElementDataDefinition => {
    // returns the result of the inner operation without furhter ado
    // thus returns a fresh single object each iteration (see inner reduce comment)
    console.log('This is input array',{type: typeof inputArray, inputArray, isarray: _.isArray(inputArray)})
    
    if (! _.isArray(inputArray)) {
        throw new Error(`Input is no array ${inputArray}`)
    }
    return inputArray.reduce(
      (result, obj) =>
        // returns an object which gets constructed by comparison between objects from the outer operation
        // and the prefilled aggregate, which is used as acc 'result'.
        Object.entries(result)
          .filter(([prop, val]) => prop in obj)
  
          // starts each time with an empty object and finally this (by then filled) object
          // will be passed to the outer reduce, where it will be replacing the outer acc.
          // during the next outer array's iteration, the inner iteration over the outer acc starts again
          // this time, that very `outer acc` contains the acc, which has been created here during the last iteration
          // thus, the outer acc starts with a relatively large set and will shrink (or stay) during each iteration.
          .reduce(
            (acc, [prop, val]) => {
              console.log('this is the obj', { obj, prop, val });
              return {
                ...acc,
                [prop]: obj[prop] === val ? val : null,
              };
            }, 
            // ({
            //   ...acc,
            //   [prop]: obj[prop] === val ? val : null,
            // }),
            {},
          ),
      aggregate,
    );
  }

  

// provides an object which contains all values which are identical
// across all objects from meta
export const buildCommonValuesAlternative = (
    meta: cytoscape.ElementDataDefinition[],
  ) : { [k: string]: uiDuck.field_type } => {
    // we prefill commonvalues with the first entry of meta array (if it exists)
    if (
      !Array.isArray(meta) ||
      meta.length == 0 ||
      typeof meta[0] === 'undefined'
    ) {
      console.log('input and aggregate meta has no entries', { meta });
    }
  
    console.log('passing by here too - build commonvalues', { meta });
  
    // initialize the getCommonProperties function with the first entry of meta
    // as ... else the typerequirement for getCommonProperties is broken
    let commonvalues = meta[0] as cytoscape.ElementDataDefinition ;
  
    console.log('these are the common values now', { meta, commonvalues });
  
    commonvalues = getCommonProperties(commonvalues, meta);
  
    console.log('input and aggregate the result is here now', { meta, commonvalues });
  
    // let newcommonvalues: {[key: string]: uiDuck.field_type} = {}
    const newcommonvalues: { [key: string]: uiDuck.field_type } = {};
    console.log('the common values are start', { meta, commonvalues, newcommonvalues });
    Object.entries(commonvalues)
      .filter(([k, v]) => v !== null && !Array.isArray(v) && typeof v === 'object' && 'type' in v )
      .map(([k, v]) => {
        if ('type' in v && 'values' in v && 'checkIfValid' in v ){
          console.log('xxxxxy its an ojbect ', { k, v });
          return (newcommonvalues[k] = v as uiDuck.field_type );
        }
      });
  
      console.log('the common values here are',{commonvalues, entries : Object.entries(commonvalues)})
    Object.entries(commonvalues)
      .filter(([k, v]) => Array.isArray(v) || typeof v === 'string')
      .map(([k, v]) => {
        if (Array.isArray(v) && v.some(ele => typeof ele !== 'string')) {
          throw new Error('Only String allowed in Array');
        }
        console.log('xxxxxy its a string ', { k, v, commonvalues });
        newcommonvalues[k] = {
          type: 'textfield',
          checkIfValid: () => true,
          values: { default: '', selected: v as string },
        };
      });
  
    Object.entries(newcommonvalues).map(([k, v]) =>
      console.log('This is the newcommon kv', { k, v }),
    );
  
    const othernewcommonvalues = Object.fromEntries(
      Object.entries(newcommonvalues).filter(([k, v]) => v !== null),
    );
    return othernewcommonvalues
  };
  