import _ from "lodash";
import { createDuplicateCollection } from "./helper";

export const otherTypeDefaults =  {
  arrtest: {
    ffffff: {
      mandatory: true,
      type: 'text',
      array: true,
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'text',
        min: '',
        max: null,
        default: ['vala', 'valb'],
        selected: null,
      },
    },
  },
  filter: {
    label: {
      mandatory: true,
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        dropdowncontent: 'technical',
        elementtype: true,
        type: 'dictionary.keys',
        min: '',
        max: null,
        default: 'something',
        selected: null,
      },
    },
    comparison: {
      mandatory: true,
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'text',
        min: '',
        max: null,
        default: 'test',
        selected: null,
      },
    },
    combinator: {
      // just edge
      mandatory: true,
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'text',
        min: '',
        max: null,
        default: 'just for edge',
        selected: null,
      },
    },
    value: {
      mandatory: true,
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        // dependingOn means, this field depends on the field called like in `dependingOn` from the same type.
        // ie in this case, filter.value depends on filter.label
        dependingOn: 'label',
        dropdowncontent: 'technical',
        elementtype: true,
        type: 'dictionary.values',
        min: '',
        max: null,
        default: 'something',
        selected: null,
      },
    },
  },
  // Define the "BusinessProcess" type
  knowledge: {
    label: {
      mandatory: true,
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'text',
        min: '',
        max: null,
        default: 'something',
        selected: null,
      },
    },
    description: {
      mandatory: true,
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'text',
        min: '',
        max: null,
        default: 'test',
        selected: null,
      },
    },
  },
  // Define the "BusinessProcess" type
  todo: {
    label: {
      mandatory: true,
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'text',
        min: '',
        max: null,
        default: 'something',
        selected: null,
      },
    },
    location: {
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'text',
        min: '',
        max: null,
        default: '',
        selected: null,
      },
    },
    description: {
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'thefoobar',
        min: '',
        max: null,
        default: 'test',
        selected: null,
      },
    },
    duration: {
      mandatory: true,
      type: 'interval',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      // values: {min: {duration: 0, unit: 'm'}, max:null, default: {duration: 10, unit: 'm'}}
      values: {
        type: 'duration',
        min: '0 min',
        max: '10 min',
        default: '0 min',
        selected: '0 min',
      },
    },
    physical_effort: {
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      // values: {min: {duration: 0, unit: 'm'}, max:null, default: {duration: 10, unit: 'm'}}
      values: {
        type: 'text',
        min: '',
        max: null,
        default: 'low',
        selected: null,
      },
    },
    mental_effort: {
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      // values: {min: {duration: 0, unit: 'm'}, max:null, default: {duration: 10, unit: 'm'}}
      values: {
        type: 'text',
        min: '',
        max: null,
        default: 'medium',
        selected: null,
      },
    },
    date: {
      type: 'date',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      // values: {min: {duration: 0, unit: 'm'}, max:null, default: {duration: 10, unit: 'm'}}
      values: {
        type: 'date',
        min: 'today',
        max: null,
        default: 'today',
        selected: 'today',
      },
    },
  },
  // Define the "BusinessProcess" type
  buy: {
    label: {
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'text',
        min: '',
        max: null,
        default: 'something',
        selected: null,
      },
    },
    store: {
      mandatory: true,
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'text',
        min: '',
        max: null,
        default: 'test',
        selected: null,
      },
    },
    quantity: {
      mandatory: true,
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      // values: {min: {duration: 0, unit: 'm'}, max:null, default: {duration: 10, unit: 'm'}}
      values: {
        type: 'text',
        min: '0',
        max: '100',
        default: '0',
      },
    },
  },
  // Define the "BusinessProcess" type
  code: {
    label: {
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'text',
        min: '',
        max: null,
        default: 'something',
        selected: null,
      },
    },
    code_type: {
      mandatory: true,
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'text',
        min: '',
        max: null,
        default: 'something',
        selected: null,
      },
    },
    belongs_to_class: {
      mandatory: true,
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'text',
        min: '',
        max: null,
        default: 'self-',
        selected: null,
      },
    },
    contains_function: {
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      // values: {min: {duration: 0, unit: 'm'}, max:null, default: {duration: 10, unit: 'm'}}
      values: {
        elementtype: 'code_type',
        type: 'function',
        min: '#000000',
        max: '#FFFFFF',
        default: true,
        selected: true,
      },
    },
    description: {
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'text',
        min: '',
        max: null,
        default: 'test',
        selected: null,
      },
    },
  },
  // Define the "BusinessProcess" type
  start: {
    new_instance: {
      // the type of the final value?
      type: 'text',
      // this function needs to resolve to true to accept the final value
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      // Retrieve / build possible Values
      values: {
        changeFunction: (bar: {
          id: (string | undefined)[],
          cy: cytoscape.Core,
          foo: { label: string; settingData: Object }
        }) => {
          if (bar.id.length > 1){
            console.warn('Currently functions are only for one element')
            return false
          }

          console.log('The button was clicked', bar, {bar,  cy: bar.cy, stack: new Error()})
          // identify elements of that node which are not an instance
          if (typeof bar.cy == 'undefined'){
            return false
          }

          // successors are these, which don't include isinstance
          const successors = bar.cy.$(`[process = "${bar.foo.label}"][type = "start"]`).successors('[^isinstance]')

          // create new instance
          
          const largest = bar.cy.$(`[process = "${bar.foo.label}"][type = "start"]`)
            .successors('[?isinstance]')
            .nodes()
            // .map(instance => instance.data('isinstance'))
            .reduce((max, current) => Math.max(max, current.data('isinstance')), -1);

          // successors.nodes().map(node => 
          //   createElementAndEdge({ isfilter: false, relationLabel:'', sourceId: f.data('id'), graphFilters, position: e.position, dispatch, cy})
          // )
          
          console.log('next stepp is here0')
          // let startpoint = _.cloneDeep(bar.cy.getElementById(bar.id[0] as string));  // Assuming the id of the starting node
          let startpoint = bar.cy.getElementById(bar.id[0] as string)
          console.log('next stepp is here', startpoint.data())

          console.log('next stepp is here1')

          const foo = createDuplicateCollection(bar.cy, successors, 1, startpoint.id())
          
          console.log('next stepp is here2', foo)
          try {
            // add instance info
          foo.forEach(ele => {
            
            ele.data.isinstance = `${largest+1}`
          })

          bar.cy.add(foo)
          // const edges = foo.filter(ele => typeof ele.data.source !== 'undefined' )
          // const nodes = foo.filter(ele => typeof ele.data.source === 'undefined' )
          // console.log('this is edges and nodes', {ed: edges.map(e => e.data), nd: nodes.map(n => n.data)})
          // bar.cy.add(nodes)
          // bar.cy.add(edges)


          console.log('next stepp is here3', foo)

          // Once cloning is done, add the nodes and edges to the main graph
          // bar.cy.add({...tempCollection.nodes});  // Add all nodes at once
          // bar.cy.add({...tempCollection.edges});  // Add all edges at once
          
          // // Start the cloning from the `startpoint` node
          // cloneSuccessors(startpoint, largest + 1);

          console.log('these are those', {b: bar.cy.$(`[isinstance = "${largest+1}"]`).map(inst => inst.data())})

          
          } catch (error) {
            console.log('some error here', error)

          }
          
          return true;
          

        },
        type: 'button',
        min: '',
        max: null,
        default: 'New Start',
        selected: null,
      },
    },
    label: {
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'text',
        min: '',
        max: null,
        default: '',
        selected: null,
      },
    },
  },
  process: {
    name: {
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'text',
        min: '',
        max: null,
        default: '',
        selected: null,
      },
    },
    process: {
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'text',
        min: '',
        max: null,
        default: '',
        selected: null,
      },
    },
    type: {
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'text',
        min: '',
        max: null,
        default: '',
        selected: null,
      },
    },
    steps: {
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'text',
        min: '',
        max: null,
        default: '',
        selected: null,
      },
    },
  },
  processstep: {
    name: {
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'text',
        min: '',
        max: null,
        default: '',
        selected: null,
      },
    },
    value: {
      mandatory: true,
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        // dependingOn means, this field depends on the field called like in `dependingOn` from the same type.
        // ie in this case, process-step.value depends on process-step.name
        dependingOn: 'name',
        dropdowncontent: 'technical',
        elementtype: true,
        type: 'dictionary.values',
        min: '',
        max: null,
        default: 'something',
        selected: null,
      },
    },
    resources: {
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'text',
        min: '',
        max: null,
        default: '',
        selected: null,
      },
    },
  },
  process_old: {
    anew: {
      // the type of the final value?
      type: 'text',
      // this function needs to resolve to true to accept the final value
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      // Retrieve / build possible Values
      values: {
        changeFunction: (bar: any) => {
          const fun = (a: {
            foo: { cy: cytoscape.Core; content: Object }
          }) => {
            const processes = a.foo.cy
              .elements()
              .filter(':visible')
              .filter("[type = 'process']");
            console.log( 'current debug: the retrieval for process nodes is triggered', { processes } );

            return true;
          };
          fun(bar);
        },
        type: 'button',
        min: '',
        max: null,
        default: 'New Start',
        selected: null,
      },
    },
    name: {
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'text',
        min: '',
        max: null,
        default: '',
        selected: null,
      },
    },
    description: {
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'thefoobar',
        min: '',
        max: null,
        default: 'The Description',
        selected: null,
      },
    },
    isChecked: {
      type: 'boolean',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      // values: {min: {duration: 0, unit: 'm'}, max:null, default: {duration: 10, unit: 'm'}}
      values: {
        type: 'switch',
        min: '#000000',
        max: '#FFFFFF',
        default: 'true',
        selected: 'true',
      },
    },
    steps: {
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'chipfield',
        min: '',
        max: null,
        default: 'all steps',
        selected: null,
      },
    },
    process: {
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'text',
        min: '',
        max: null,
        default: '',
        selected: null,
      },
    },
    wanting: {
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      // values: {min: {duration: 0, unit: 'm'}, max:null, default: {duration: 10, unit: 'm'}}
      values: {
        elementtype: true,
        type: 'goal',
        min: '#000000',
        max: '#FFFFFF',
        default: true,
        selected: true,
      },
    },
    duration: {
      type: 'interval',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      // values: {min: {duration: 0, unit: 'm'}, max:null, default: {duration: 10, unit: 'm'}}
      values: {
        type: 'color',
        min: '#000000',
        max: '#FFFFFF',
        default: '#000000',
        selected: '#5555FF',
      },
    },
  },
  // scqa: {
  //   situation: '',
  //   complication: '',
  //   question: '',
  //   answer: '',
  // },
  // qgate: {
  //   scqa: SCQA
  // },
  // goal:  // Define the "BusinessProcess" type
  //   {
  //     description: "",
  //     isCompleted: false,
  //     q25: QGate,
  //     q50: QGate,
  //     q75: QGate,
  //     q100: QGate,
  //   },
  requirement: {
    requirementID: {
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'text',
        min: '',
        max: null,
        default: '',
        selected: null,
      },
    },
    feature: {
      mandatory: true,
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'text',
        min: '',
        max: null,
        default: '',
        selected: null,
      },
    },
    required_testcase: {
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      // values: {min: {duration: 0, unit: 'm'}, max:null, default: {duration: 10, unit: 'm'}}
      values: {
        elementtype: true,
        type: 'testcase',
        min: '#000000',
        max: '#FFFFFF',
        default: true,
        selected: true,
      },
    },
    description: {
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'text',
        min: '',
        max: null,
        default: '',
        selected: null,
      },
    },
    // source                      : RequirementSource,
    // priority                    : RequirementPriority, // Using the enum here
    // type                        : RequirementType, // Using the enum here
    // dependencies                : Tasks[],
    // constraints                  : Constraints[],
    // acceptanceCriteria          : AcceptanceCriterion[],
    // validationMethod            : ValidationMethod,
    associatedRisks: {
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'text',
        min: '',
        max: null,
        default: '',
        selected: null,
      },
    },
    // lifecycleStage              : LifecycleStage, // Using the enum here
    // changeHistory                : ChangeRecord[],
    // owner                        : {Person, Role},
    approvalReviewProcess: {
      type: 'text',
      checkIfValid: (value: { duration: number; unit: string }) =>
        value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
      values: {
        type: 'text',
        min: '',
        max: null,
        default: '',
        selected: null,
      },
    },
  },
}