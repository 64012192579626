export const getApiBaseUrl = (): string => {
  if (typeof window === 'undefined'){
    return 'localhost:3300'
  }
  const protocol = window?.location?.protocol ?? 'http';
  const hostname = window?.location?.hostname ?? 'localhost';
  const port = window?.location?.port ? `:${window?.location?.port}` : (process.env.NODE_ENV === 'production') ? '' : ':3300';
  const url = `${protocol}//${hostname}${port}`;
  return url;
};
