import { ofType, StateObservable } from 'redux-observable';
import * as operators from 'rxjs';
import * as _ from 'lodash';
// import fetch from 'node-fetch';
import * as helper from '../helper';
import { reconstructNode } from '../api/neo4jhelper';
import {
  types as uiActionTypes,
  initialState as uiInitialState,
} from './uiDuck';
import { getApiBaseUrl } from '../api/url';
import { RootState } from '../rootState';
import { Action, AnyAction, Dispatch } from 'redux';
import { Observable } from 'rxjs';




const name = 'time';
export const types = {
  NAME: `${name}`,
  ADD_TASK: `${name}/ADD_TASK`,
};

export interface InitialState {
  buckets: Object,
  currentProject: Object,
}


// selector functions
export const select = {
  buckets: (state: RootState) => state[`${name}App`].buckets,
  currentProject: (state: RootState)=> state[`${name}App`].currentProject,
};


// action creator
export const actions = {
  addTask: (value: { project?: string, taskname?: string, date?: string, duration?: string } = {}) => ({
    type: types.ADD_TASK,
    payload: value,
  }),
};

export const dispatcher = {  
  addTask: (dispatch: Dispatch, value: Object) => {
    dispatch(actions.addTask( value ))
  }
}
;

export const initialState = {
  buckets: {},
  currentProject: {},
};

export default function reducer(state = initialState, action: AnyAction): InitialState {
  switch (action.type) {
    case types.ADD_TASK:
      return {
        ...state,
        currentProject: Object,
      }
    default:
      return state;
  }
}

export const epics = {
};
