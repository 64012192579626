import React, { EffectCallback, useEffect, useRef } from 'react';
import { Dispatch } from 'redux';
import * as _ from 'lodash';
import * as uiDuck from './ducks/uiDuck';
import { InitialState as UiInitialState } from './ducks/uiDuck';
import cytoscape from 'cytoscape';
// import { Collection } from '@types/cytoscape';
// import * as Cy from '@types/react-cytoscapejs'
import { v4 as uuidv4 } from 'uuid';
// import * as fs from 'fs'

import { delay } from 'lodash';
import { OneLeft } from '../types/types';

// // Generates a 32-character hexadecimal string
// const generateRandomFilename = () => crypto.randomBytes(16).toString('hex');

// const createNewPath = (prefix, originalFilename, randomString) => path.join(prefix, `${randomString}${originalFilename}`);

// // Recursive function to find a unique file path
// const findUniqueFilePath = async (prefix, originalFilename, fileExists) => {
//   const newPath = createNewPath(prefix, originalFilename, '');
//   const exists = await fileExists(newPath);
//   const randomString = generateRandomFilename();

//   return exists ? findUniqueFilePath(originalFilename, fileExists) : newPath;
// };

// /**
//  * check if file exists
//  *
//  * @param filePath
//  * @returns boolean
//  */
// async function fileExists(filePath: fs.PathLike ): boolean {
//   try {
//     await fs.access(filePath);
//     return true; // The file exists
//   } catch (error) {
//     return false; // The file does not exist
//   }
// }

/**
 * calculates bytes to human-readable format with automatic unit selection
 * @param bytes The number of bytes to convert.
 * @returns A string representing the human-readable format of bytes.
 */
export const bytesToSize = (bytes: number): string => {
  if (bytes < 0) throw Error('non-negative values only'); // Ensure input is not negative

  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 B'; // Handle zero bytes case

  const i = Math.floor(Math.log(bytes) / Math.log(1024)); // Calculate log base 1024 of bytes

  // Round to two decimal places and format with the appropriate size unit
  return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i]; // Corrected rounding to two decimal places
};


/**
 * check if url exists,
 * throw error if not - thus the function can be called without
 * further checking in a consumer.
 *
 * @param url : string
 * @returns boolean | Error
 */
export const checkIsUrl = ({
  url,
  throwError = true,
}: {
  url: string
  throwError?: boolean
}): boolean | Error => {
  let allOk = false;
  try {
    new URL(url);
    allOk = true;
  } catch (e) {
    allOk = false;
  }

  if (!allOk && throwError) {
    throw new Error('Url is not valid');
  } else {
    return allOk;
  }
};
export const checkIsUrlNoThrow = ({
  url,
}: {
  url: string
}): boolean | Error => {
  let allOk = false;
  try {
    new URL(url);
    allOk = true;
  } catch (e) {
    allOk = false;
  }
  return allOk;
};

/**
 * wrapper object with a Promise and custom resolve/reject functions.
 *  can control when the Promise is resolved or rejected.
 *
 *
 *

      // Example usage with an injected resolver function for timeout
      const timeoutResolver = (resolve, reject) => {
        // Simulating a timeout-based resolution
        setTimeout(() => {
          resolve("Promise resolved after timeout!");
        }, 3000); // Wait for 3 seconds before resolving
      };

      const mypro = createCustomPromise(timeoutResolver);

      // Later, you can still manually resolve or reject the promise:
      // mypro.resolve("Manually resolved!");
      // or
      // mypro.reject(new Error("Manually rejected!"));

      // You can use the promise as usual
      mypro.promise
        .then((result) => {
          console.log(result); // Output after 3 seconds: "Promise resolved after timeout!"
        })
        .catch((error) => {
          console.error(error);
        });
 * @returns {promises, resolve, reject}
 */
// export const createCustomPromise = (resolverFunc: Function | null = null) => {
//   let resolveFunc;
//   let rejectFunc;
export const oldcreateCustomPromise = (
  resolverFunc:
  | ((resolve: (value?: any) => void, reject: (reason?: any) => void) => void)
  | null = null,
) => {
  let resolveFunc: (value?: any) => void;
  let rejectFunc: (reason?: any) => void;

  const promise = new Promise((resolve, reject) => {
    resolveFunc = resolve;
    rejectFunc = reject;

    // Optionally, you can call the external resolver function here
    // if it's provided, and it will resolve/reject the Promise.
    if (typeof resolverFunc === 'function') {
      resolverFunc(resolve, reject);
    }
  });

  return {
    promise,
    resolve: (value: any) => resolveFunc(value), // Explicitly specifying the type of 'value'
    reject: (reason: any) => rejectFunc(reason), // Explicitly specifying the type of 'reason'
  };
};

// export const createCustomPromiseO = <T = unknown, E = unknown>(
//   resolverFunc:
//     | ((resolve: (value?: T) => void, reject: (reason?: E) => void) => void)
//     | null = null
// ) => {
//   let resolveFunc: (value?: T) => void
//   let rejectFunc: (reason?: E) => void

//   const promise = new Promise<T>((resolve, reject) => {
//     resolveFunc = resolve
//     rejectFunc = reject

//     if (typeof resolverFunc === 'function') {
//       resolverFunc(resolve, reject)
//     }
//   })

//   return {
//     promise,
//     resolve: (value: T) => resolveFunc(value), // Now explicitly using generic type T
//     reject: (reason: E) => rejectFunc(reason), // Now explicitly using generic type E
//   }
// }

export const createCustomPromise = <T = unknown, E = unknown>(
  resolverFunc: 
    | ((
      resolve: (value: T | PromiseLike<T>) => void,
      reject: (reason?: E) => void,
    ) => void)
    | null = null,
  ): {
  promise: Promise<T>;
  resolve: (value: T | PromiseLike<T>) => void;
  reject: (reason?: E) => void;
  } => {
    let resolveFunc!: (value: T | PromiseLike<T>) => void; // Use definite assignment assertion
    let rejectFunc!: (reason?: E) => void; // Use definite assignment assertion

    const promise = new Promise<T>((resolve, reject) => {
      resolveFunc = resolve;
      rejectFunc = reject;

      if (typeof resolverFunc === 'function') {
        resolverFunc(resolve, reject);
      }
    });

    return {
      promise,
      resolve: (value: T | PromiseLike<T>) => resolveFunc(value), // Explicitly using T | PromiseLike<T>
      reject: (reason?: E) => rejectFunc(reason), // No change needed here
    };
  }

/**
 *
 * checks if the types' property has to have a certain element type
 * ie this leads to y/n decision if a specific element type is needed
 *
 * there's ano
 * @param type
 * @param property
 * @returns
 */
export const type_is_required = (
  type: string,
  property: string,
): false | string => {
  // const types = uiDuck.initialState.typeDefaults[property]
  const types = uiDuck.propertyTypes[type]?.[property];

  console.log('Type Check in Helper is searching for', { property, type, types, sel: uiDuck.propertyTypes[type] });
  if (typeof types === 'undefined') {
    return false;
  }

  console.log('Type Check in Helper found here', types);
  console.log('Type Check in Helper found', { property, types });
  return types;
};

export const dynamicRequire = async (modulePath: string): Promise<any> => {
  try {
    const module = await import(modulePath);
    return module;
  } catch (error) {
    console.error('Error loading module:', error);
    throw error;
  }
};

export const object_to_array = (obj: { [key: string]: any }) =>
  Object.keys(obj).map((a: any) => obj[a]);

// waits for timeout before executing the provided curried function
// export const debounce = (func, timeout = 300) => {
//   let timer
//   return (...args) => {
//     clearTimeout(timer)
//     timer = setTimeout(() => {
//       func.apply(this, args)
//     }, timeout)
//   }
// }

// prepares an async debounce function which can be started for timeout at a later point in time
// replaced with new one below
export const debounceAsync = (func: Function, wait = 300) => {
  let timerID: NodeJS.Timeout;
  return async (...args: Array<any>) => {
    clearTimeout(timerID);

    const promiseForFunc = new Promise(resolve => {
      timerID = setTimeout(resolve, wait);
    });

    return promiseForFunc.then(() => func(...args));
  };
};

// export const newdebounceAsync = <T extends any[], R>(
//   func: (...args: T) => Promise<R> | R,
//   wait = 300
// ) => {
//   let timerID: NodeJS.Timeout | undefined;

//   return (...args: T): Promise<R> => {
//     clearTimeout(timerID);

//     const promiseForFunc = new Promise<R>((resolve) => {
//       timerID = setTimeout(() => resolve(func(...args)), wait);
//     });

//     return promiseForFunc;
//   };
// };

// prepares a debounce function which can be started for timeout at a later point in time

// export const debounce = (func, timeout = 300) => {
//   let timer;
//   return (...args) => {
//     clearTimeout(timer);
//     timer = setTimeout(() => {
//       func.apply(this, args);
//     }, timeout);
//   };
// };

export const debounce = (
  func: (...args: any[]) => void,
  timeout = 300,
): ((...args: any[]) => void) => {
  let timer: ReturnType<typeof setTimeout> | undefined;
  return (...args: any[]) => {
    // Clear the timeout if it exists
    if (timer !== undefined) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      // The context (`this`) inside setTimeout will be the global object or undefined in strict mode,
      // unless explicitly bound. Consider using `func(...args)` if `this` binding is not required.
      func.apply(this, args);
    }, timeout);
  };
};

// https://joecortopassi.com/articles/functional-composition-in-javascript/
export const compose =
  (funcs: Function[]): ((arg: any) => any) =>
    arg =>
      funcs.reduce(
        (a, b) => (arg: any) => b(a(arg)),
        (i: any) => i,
      );

export const filterMap = (
  checker: Function,
  mapper: Function,
  list: Array<any>,
): Array<any> =>
  list.reduce(
    (acc, current) =>
      checker(current) ? acc.push(mapper(current)) && acc : acc,
    [],
  );

const deepCompareEquals = (a: any, b: any) => {
  // TODO: implement deep comparison here
  // something like lodash

  const eq = _.isEqual(a, b);
  console.log('here we compare', eq, a, b);

  return eq;
};

const useDeepCompareMemoize = (value: any) => {
  const ref = useRef();
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

export const useDeepCompareEffect = (
  callback: EffectCallback,
  dependencies: React.DependencyList,
) => {
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
};

// equality function
export const customEqual = (oldValue: any, newValue: any) =>
  _.isEqual(JSON.stringify(oldValue), JSON.stringify(newValue));

// list of properties from the left
export const ret_items = (theleft: OneLeft): { [key: string]: any } => {
  const menuItems: { [key: string]: any }[] = [];

  // var menuItems = thelefts.map(theleft => {
  const retObject: { [key: string]: any } = {};
  if (Object.keys(theleft.nodes).length !== 0 ){

    console.log('these are some left nodes', {theleft})
    // Object.entries((theleft.nodes as cytoscape.NodeDefinition[] ) ?? {data: {}}).data.map(([key, val]) => {
    //   retObject[key] = val;
    // });
  }
  // })

  console.log('debughere menuitems calculated', menuItems, theleft);
  return retObject;
};

/**
 * timeout
 * @param duration
 * @returns
 */
export const wait = (duration: number) =>
  new Promise<void>(resolve => setTimeout(resolve, duration));

/**
 * receives array of element json-data elements
 *
 *
 * @param elementJson
 */
export const selected2Meta = (dispatch: Dispatch, elementJsonArr: cytoscape.ElementDataDefinition[]) => {
  console.log('setting meta', elementJsonArr);
  uiDuck.dispatcher.showMeta(dispatch, elementJsonArr);
};

// @ts-ignore
export const removePage = (dispatch, { activeStep, max } = {}) => {
  console.log('remove handlingStepChange here', activeStep);

  // set larger element active if it exists, else lower
  let nextActive;
  if (max > activeStep) {
    // when deleting the active step, the next element falls into its place
    nextActive = activeStep;
  } else {
    nextActive = activeStep - 1;
  }

  uiDuck.dispatcher.removepage(dispatch, { old: activeStep, new: nextActive });

  console.log(`Removed ${activeStep} and now on page with id: ${nextActive}`);
};

// looks up the menuitem matching the chosen event-item-id and sets it as meta
// also looks up relationships entering / leaving this node
export const toggleMaxview = (
  menuItems: Array<any>,
  targetId: string,
  dispatch: Dispatch,
) => {
  const thenew = menuItems.find(item => item.id == targetId);

  // only add if issue is being edited
  if (typeof thenew !== 'undefined') {
    uiDuck.dispatcher.highlight(dispatch, [targetId]);
  } else {
    console.log('Didnt find Item with Id', targetId, menuItems);
  }

  uiDuck.dispatcher.toggle_meta_view(dispatch, thenew);
};

export const onlyUnique = (value: any, index: number, self: any[]): boolean =>
  self.indexOf(value) === index;

/**
 * looks up visible entries in cy
 *
 * adds these to newly created dictionary
 * uniques and sorts that dictionary
 *
 * @param cy
 * @returns sorted Dictionary of visible elements
 */
export const calculateVisibleCyDictionary = (cy: cytoscape.Core): Object => {
  const newDict: { [key: string]: any[] } = {};
  // const currentElements1 = cy.$(':visible')
  // const currentElements = cy.$(':visible[type!="structure"]').jsons();
  const currentElements = cy.$(':visible[type!="structure"]');

  console.log('CalculateVisibleCyDict Current Elements', currentElements);
  // Object.entries(currentElements).map(([elek, eleval]) => {
  currentElements.map(eleval => {
    Object.entries(eleval.data()).map(([dk, dv]) => {
      console.log('New Dict is returned ekev ', { dk, dv });
      if (typeof newDict[dk] === 'undefined') {
        // if that field hasn't been defined, add empty array
        newDict[dk] = [];
      }

      // very ugly - find out why label gets nulled when selected in editcard
      // and remove this section
      if (dk === 'label' && dv == null){
        console.log('preventing label from null', dv, dk, eleval.data('name'))
        dv = eleval.data('name')
      }

      // push value to existing array
      newDict[dk].push(dv);
    });
  });

  console.log('this is the newdict for all visible', newDict)
  Object.keys(newDict).map(key => {
    // ensure array of values is unique
    newDict[key] = newDict[key].filter(onlyUnique).sort();
  });
  console.trace('this is the newdict for unique', newDict)

  console.log('New Dict is returned', { newDict });
  return newDict;
};

export const applyDynamicFilter = (
  valuex: any,
  { comparison, value }: StorageFilter,
): boolean => {
  if (typeof value === 'undefined' && !(comparison == '=' || comparison == '!=')){
    return false
  }
  let res = false;
  switch (comparison) {
    case '=':
      res = valuex == value;
      break;
    case '!=':
      res = valuex != value;
      break;
    case '>':
      res = valuex > value!;
      break;
    case '<':
      res = valuex < value!;
      break;
    case '>=':
      res = valuex >= value!;
      break;
    case '<=':
      res = valuex <= value!;
      break;
    default:
      res = false; // or throw an error
  }
  // if (res){

  //   console.log('the quest here res', {res, value, comp, val})
  // }
  return res;
};

/**
 * looks up entries in cy which match a filter and have a specific parameter set
 *
 * adds these to newly created dictionary
 * uniques and sorts that dictionary
 *
 * @param cy
 * @returns sorted Dictionary of visible elements
 */
export const calculateFilteredCyDictionary = (
  cy: cytoscape.Core,
  filter: { comp: string; val: string }[],
  param: any,
): Object => {
  const newDict: { [key: string]: any[] } = {};
  // const currentElements1 = cy.$(':visible')

  console.log('the current elements input', filter, param);

  const filterstringarray: string[] = [];

  Object.entries(filter)
    .filter(
      ([fkey, fval]) =>
        /* exclude the filter we want to change */ fkey != param,
    )
    .map(([fkey, fval]) => {
      console.log('the current elements process', fkey, fval);

      return filterstringarray.push(`[${fkey}${fval.comp}"${fval.val}"]`);
    });

  const currentElements = cy.$(
    `[type!="structure"]${filterstringarray.join('')}[${param}]`,
  );
  console.log( 'the current elements', currentElements, 'found with: ', `[type!="structure"]${filterstringarray.join('')}[${param}]` );

  currentElements.map(eleval => {
    Object.entries(eleval.data)
      .filter(
        ([dk, dv]) =>
          /* we are interested only in the provided filtercolumn */
          dk == param,
      )
      .map(([dk, dv]) => {
        if (typeof newDict[dk] === 'undefined') {
          // add new array for column if it's not yet available
          newDict[dk] = [];
        }
        // add new value to item
        // console.log('Adding the value to the dictionary',{param, dk, dv, evdata: eleval.data})
        newDict[dk].push(dv);
      });
  });

  Object.keys(newDict).map(key => {
    newDict[key] = newDict[key].filter(onlyUnique).sort();
  });

  return newDict[param];
};

/**
 * Timeout for x Milliseconds
 * @param ms 
 * @returns 
 */
export const sleep = (ms: number): Promise<void> => {
  return new Promise(resolve => delay(resolve, ms));
};
// Defines the structure for current filters and workaround object

export interface CyFilterCombo {
  nodes: { [key: string]: CyFilter }
  edges: { [key: string]: CyFilterEdge }
}

export interface StorageFilterCombo {
  nodes: { [key: string]: StorageFilter }
  edges: { [key: string]: CyFilterEdge }
}

export interface CyFilter {
  id?: string
  isparent?: boolean
  parent?: string
  label?: string
  comparison?: string
  value?: Array<string> | string
}

export interface CyFilterNodeDefinition {
  data: CyFilter
  position: cytoscape.Position
}
export interface CyFilterEdgeDefinition {
  data: CyFilterEdge
}
// includes position - that's separate in cyfilter
export interface StorageFilter {
  id?: string
  isparent?: boolean
  parent?: string
  label?: string
  comparison?: string
  value?: Array<string> | string
  position?: { x: number; y: number }
}

export interface CyFilterEdge {
  id: string
  source: string
  target: string
  combinator: string
  not?: boolean
}

export interface Settings {
  [key: string]: {
    type: string
    default: any
    min?: any
    max?: any
    list?: any[]
    selected?: any
  }
}

export const convertStorageToCyFilter = (
  storageFilter: StorageFilterCombo,
): CyFilterCombo => {
  return storageFilter as CyFilterCombo;
};

export const convertCyFilterToStorage = (
  cyFilterCombo: CyFilterCombo,
  position: cytoscape.Position,
): StorageFilterCombo => {
  return { ...cyFilterCombo, position } as StorageFilterCombo;
};

const keyExists = (
  obj: { [key: string | number]: any },
  key: string | number,
): boolean => typeof obj[key] !== 'undefined';

export const testOnly_retrieveResults = (
  cy: cytoscape.Core,
  source: cytoscape.NodeSingular,
  target: cytoscape.NodeSingular,
  logic: string,
  info: {
    processed: { [key: string]: cytoscape.Collection }
    pointer: { [key: string]: string }
    fcy: cytoscape.Collection
    cy: cytoscape.Core
  },
) => {
  return retrieveResults(cy, source, target, logic, info);
};

/**
 *
 * @param cy
 * @param noi
 * @param info
 * @returns
 */
const retrieveData = (
  cy: cytoscape.Core,
  noi: cytoscape.NodeSingular,
  info: Info,
): cytoscape.Collection => {
  console.log('the trace', { tr: console.trace() });
  console.log('Starting Retrieve Data with Parameter', { nd: noi.data(), info, ipo: info.pointer[noi.data('id')], iproofip: info.processed[info.pointer[noi.data('id')]], ip: info.pointer, ipro: info.processed });

  let process: string | cytoscape.Collection = createFilterString(noi);
  if (typeof noi.data().select !== 'undefined') {
    process = noi.data().select;
  }

  // if filtering happens, this ensures to ignore elements which do not have the value at all
  if (noi.data('value') == '0' && typeof process == 'string'){
    // process = '[num = "0"]' will be process = '[num = 0]'
    process = process.replace('"0"', '0');
  }

  console.log('this is the select now', {nd: noi.data(), process , c: cy.elements(`[${noi.data('label')}]`).map(ele => ele.data())});
  let data: cytoscape.Collection | null = null;
  const cp: cytoscape.Collection = process as unknown as cytoscape.Collection;
  console.log('this is cp here', { cp, process, nd: noi?.data(), ip: info.pointer, ipro: info.processed, kex: keyExists(info.pointer, noi.data().id) });

  if (typeof cp !== 'undefined' && keyExists(info.pointer, noi.data().id)) {
    console.log('x This node has been processed already');
    // this node has been processed already, so we get the stored results from a previous
    const key = info.pointer[noi.data().id];

    data = info.processed[key];
    console.log('we end here', { key, data });
  } else if (typeof cp === 'undefined') {
    // eg parent?
    console.log('x This node might be parent?');
  } else {
    console.log('x This node is not clear');
    console.log('we end here but why ', { nd: noi.data(), all: (typeof cp.select === 'undefined' || typeof cp.select === 'function') && keyExists(info.pointer, noi.data().id), firsttwo: typeof cp.select === 'undefined' || typeof cp.select === 'function', last: keyExists(info.pointer, noi.data().id) });
    // data needs to be retrieved
    // const proval: string = process
    if (
      typeof noi.data('comparison') === 'undefined' ||
      typeof noi.data('value') === 'undefined'
    ) {
      // there are situations where the selector is undefined (ie parents)
      data = cy.collection();
      console.log('we end here but whyyyy ', { nd: noi.data(), tyco: typeof noi.data('comparison'), tyva: typeof noi.data('value') });
    } else {
      console.log('we end there but whyyyy ', { nd: noi.data(), tyco: typeof noi.data('comparison'), tyva: typeof noi.data('value') });
      data = cy.$(process as string);
    }
  }
  console.log('Thats the data we received meanwhile', { data });

  // cy.elements().map(ele =>
  //   console.log('this is an ele from cy here', {
  //     ke: Object.keys(ele.data()).some(k => noi.data('label') === k),
  //     process,
  //     data,
  //     ed: ele.data(),
  //   })
  // )

  console.log('Checking if Result exists in Pointer', { process, data, v1: cy.elements('[foo = "bar"]'), v2: cy.elements(process as string) });

  if (!data) {
    throw new Error('Data is undefined');
  }
  return data;
};

const retrieveResults = (
  cy: cytoscape.Core,
  source: cytoscape.NodeSingular,
  target: cytoscape.NodeSingular | null,
  logic: string,
  info: Info,
): cytoscape.Collection => {
  let td = null;
  if (target && target.data()) {
    td = target.data();
  }
  console.log('Retrieving Results start', { sd: source.data(), td, logic, ipo: info.pointer, ipro: info.processed });

  if (!target) {
    // single node retrieval
    return retrieveData(cy, source, info);
  }
  const processSource: cytoscape.Collection = retrieveData(cy, source, info);
  const processTarget: cytoscape.Collection = retrieveData(cy, target, info);

  console.log('Processed Source Ele is', { sid: source.id(), tid: target.id(), ed: processSource.map(ele => ele.data()), ip: info.pointer, ipro: info.processed });
  console.log('Processed Target Ele is', { sid: source.id(), tid: target.id(), ed: processTarget.map(ele => ele.data()), ip: info.pointer, ipro: info.processed });

  console.log('Processed results', { psjs: JSON.stringify(processSource.jsons()), ptjs: JSON.stringify(processTarget.jsons()), logic });

  let it;
  switch (logic) {
    case 'OR':
      it = processSource.union(processTarget) ?? cytoscape().collection();

      break;

    case 'XOR':
      it =
        processSource.symmetricDifference(processTarget) ??
        cytoscape().collection();
      break;

    case 'AND':
      const andtmp = processSource.intersection(processTarget);

      processSource.map(ele =>
        console.log('AndTmp Source Ele', { ed: ele.data() }),
      );
      processTarget.map(ele =>
        console.log('AndTmp Target Ele', { ed: ele.data() }),
      );
      console.log('AndTmp is ' + logic, { andtmp, sd: source.data().select, td: target.data().select, processSource, processTarget });

      it = andtmp ?? cytoscape().collection();
      break;

    default:
      break;
  }

  if (typeof it === 'undefined') {
    throw new Error('IT is undefined');
  }

  console.log('processed successfully: ' + logic, { it, sd: source.data().select, td: target.data().select, processSource, processTarget });
  return it;
};

// target can be null, eg if we process a single node (parent)
const writeProcessedPointer = (
  info: Info,
  newres: cytoscape.Collection,
  target: cytoscape.NodeSingular | null,
  source: cytoscape.NodeSingular,
): Info => {
  Object.entries(info.pointer).map(ele =>
    console.log('Write Processed Pointer: Element in Pointer', { ele }),
  );
  Object.entries(info.processed).map(ele =>
    console.log('Write Processed Pointer: Element in processed', { ele }),
  );
  console.log('Write Processed Pointer: source and target data', { sd: source.data(), td: target?.data(), stack: new Error() });

  // add the new result to the info.processed
  const id = uuidv4();

  // immutably add or overwrite random ID with new result
  info = { ...info, processed: { ...info.processed, [id]: newres } };

  console.log('we will remove and add', { remove: info.processed[info.pointer[source.data().id]], add: newres });
  // remove the old result (to combine the old result and the new)
  // adjust the pointers for node and targetNode
  info.processed = _.omit(info.processed, [info.pointer[source.data().id]]);
  info.pointer[source.data().id] = id;
  if (target) {
    info.processed = _.omit(info.processed, [info.pointer[target.data().id]]);
    info.pointer[target.data().id] = id;
  }

  Object.entries(info.processed).map(([k, ele]) =>
    ele.map(ee =>
      console.log('The Info returned from writeProcessedPointer', { k, ed: ee.data() }),
    ),
  );

  return info;
};

export interface Info {
  processed: { [key: string]: cytoscape.Collection }
  pointer: { [key: string]: string }
  fcy: cytoscape.Collection
  cy: cytoscape.Core
}

/**
 *
 * @param cy
 * @param source
 * @param logic
 * @param info
 * @returns
 */
const processNodeOp = (
  cy: cytoscape.Core,
  source: cytoscape.NodeSingular,
  logic: string,
  info: Info,
): Info => {
  console.log('The info is here', { ip: info.processed, ipo: info.pointer, sd: source.data(), logic, stack: new Error() });
  if (typeof info.pointer[source.data('id')] !== 'undefined') {
    console.log('Already Exists The info is here', { ip: info.processed, ipo: info.pointer, sd: source.data(), logic, stack: new Error() });
    //return info
  }
  // we need to consider that the source can be a parent.
  // in that case the result of the children need to be known.
  // that result shall be the result of this very `source` node
  // that result can be processed normally with other targets.
  let isParent = false;
  const sourcedata: CyFilter = source.data() as CyFilter;
  console.log('This is the source', { sd: sourcedata });
  if (
    typeof sourcedata.label === 'undefined' &&
    typeof sourcedata.comparison === 'undefined'
  ) {
    console.log('This is the source - it is a parent', { sd: sourcedata, first: typeof sourcedata.label === 'undefined', second: typeof sourcedata.comparison === 'undefined' });
    isParent = true;
  }

  // check if the node has an outgoer. If not, return
  const outsexist = false;

  const outs = source.outgoers();
  let outEdges: cytoscape.EdgeCollection;
  let target: cytoscape.NodeSingular | null;

  console.log('This is the final Info Start', { sd: sourcedata, logic, outs: outs.length, info });

  console.log('still here it is a parent', { isParent });
  // ensure quitting if no outs exist
  if (!outs.length) {
    if (logic == '') {
      // no outs exist, but no logic exists either
      console.log('in pronoop no len', { isParent, logic, source });
      // const tempres1 = retrieveResults(cy, source, null, logic, info)
      target = null; // we don't have an edge
      // console.log('The tempres here', {tempres1})
      // return tempres1
    } else {
      console.log('No outs here', { isParent, info, logic, sourcedata });
      return info;
    }
  } else {
    outEdges = source.outgoers().edges();
    target = info.fcy.getElementById(outEdges.first().data().target);
    console.log('Assigned a new target', { logic, target: target.data(), source: source.data() });
  }

  if (outs.length > 2) {
    // check if the node is a gate (as of now it's not by default) and if it's not, throw a ux warning if it has several outgoers.
    if (typeof source.data().gate === 'undefined') {
      console.error('Just one Connection allowed', { isParent });
      return info;
    }
  } else if (
    outs.edges().length !== 0 &&
    outs.edges().first().data().combinator !== logic
  ) {
    // if the node if is connected with the wrong logic, skip this and proceed with target
    // ie we're currently processing AND but the combinator is OR
    outs.edges().map(edge => console.log('The edge here', { ed: edge.data() }));
    console.log('skipping this here', { isParent, oc: outs.edges().first().data().combinator, source, target, logic, info });
    if (!target) {
      throw new Error(
        'Target is not defined, Outs exist, so there should be a target',
      );
    }
    return processNodeOp(cy, target, logic, info);
  }

  // here 

  console.log('We process this source and target because they are connected with the fitting logic', { sd: source.data(), td: target?.data(), logic,  oc: outs.edges().first().data()?.combinator });
  console.log('still here it is a parent 2', { isParent });

  /////
  //// start to retrieve the actual results of `source` node.
  ///  we reach here after early exits are done
  //

  let td: CyFilter | null = null;
  if (target) {
    td = target.data();
  }

  let tempres: cytoscape.Collection;

  console.log('has to process parent?', { sd: source.data(), isParent });

  let ff = '';
  if (isParent) {
    // first calculate the inner result

    // look up all the ParentCy Elements (ie its children)
    const filter = '[parent = "' + source.data().id + '"]';
    const pcy: cytoscape.Collection = info.fcy.filter(filter);
    console.log('filter the parent filters:', { pcpf: pcy.map(pf => pf.data()), filter, fcy: JSON.stringify(info.fcy.jsons()) });

    pcy.nodes().map(chi => {
      // ff += ' '+ chi.data().select
      ff += ' ' + createFilterString(chi);
      console.log('Built pcy', { ff, cd: chi.data() });
    });

    // here we move into the parent and process the children, so that the parent has a result.
    const resultOfChildrenLogicWithinParent = nextones(pcy, cy, isParent);

    console.log('Childrens Result is', { ff, sourceid: source.id(), td: target, cd: resultOfChildrenLogicWithinParent.map(chi => chi.data()) });
    // console.log('Childrens Result is', {ff,sourceid: source.id(),td: target.data(), cd: resultOfChildrenLogicWithinParent.map(chi => chi.data())})

    console.log('Childrens Result within parent:', { childrensResult: resultOfChildrenLogicWithinParent });

    // store results for? parent? children's result
    info = writeProcessedPointer(
      info,
      resultOfChildrenLogicWithinParent,
      null,
      source,
    );

    // now the parent has a result:
    Object.entries(info.processed).map(([k, v]) =>
      v.map(ele =>
        console.log('children produce this result:', { ip: info.pointer, theid: source.data().id, ff, sourcedata, td, sid: source.data().id, k, ed: ele.data() }),
      ),
    );
  }

  tempres = retrieveResults(cy, source, target, logic, info);

  console.log('The Temporary Result contains', { sid: source.id(), sd: source.data(), tid: target?.data(), edtempres: tempres.map(ele => ele.data()) });
  // console.log('The Temporary Result contains', {sid: source.id(), tid: target.id(), edtempres: tempres.map(ele =>ele.data())})

  // storing results of edge tuple
  info = writeProcessedPointer(info, tempres, target, source);

  // print
  Object.entries(info.processed).map(([k, v]) =>
    v.map(ele =>
      console.log('entries all processed:', { ff: source.data().select, sid: source.data().id, k, ed: ele.data() }),
    ),
  );

  console.log('setting the results in pr ost', { sid: source.id(), opo: info.pointer, pro: info.processed });

  if (target) {
    // process the connected Target
    console.log( `now that we processed source ${source.data().id}, we proceed with target ${target.data().id}` );
    info = processNodeOp(cy, target, logic, info);
  }

  console.log('This is the final Info ret', { info });

  console.log('going back');

  return info;
};

export const testOnly_processNodeOp = (
  cy: cytoscape.Core,
  source: cytoscape.NodeSingular,
  logic: string,
  info: {
    processed: { [key: string]: cytoscape.Collection }
    pointer: { [key: string]: string }
    fcy: cytoscape.Collection
    cy: cytoscape.Core
  },
): {
  processed: { [key: string]: cytoscape.Collection }
  pointer: { [key: string]: string }
  fcy: cytoscape.Collection
  cy: cytoscape.Core
} => {
  return processNodeOp(cy, source, logic, info);
};

/**
 *
 * takes cy and filtercy.
 * takes a filter root, gets its edge and target.
 * then it picks the appropriate combinator based on edge and retrieves a result from cy
 *
 * usually is started from `filtering`, but if it is processed from the parent-flow,
 * it is started as well from `processNodeOp`
 *
 * @param fcy
 * @param cy
 * @param isParent
 * @returns
 */
const nextones = (
  fcy: cytoscape.Collection,
  cy: cytoscape.Core,
  isParent = false,
): cytoscape.Collection => {
  // preparation to store results.
  // pointer gets mapping from root to entry in processed
  // processed gets collection of results
  const pointer: { [key: string]: string } = {};
  const processed: { [key: string]: cytoscape.Collection } = {};

  console.log('These are the inputs for nxtones', { isParent, fcy: fcy.data(), cy });

  /////
  //// Identify the roots from filter cy
  ///  no roots, no filters
  //   one root, at least one filter
  //   several roots, either
  //      - parent/child or
  //      - someone forgot to connect filters with edge
  //
  const roots = fcy.nodes().roots();

  // if there are no roots, ie no filters at all, return all.
  if (!roots.length) {
    console.error('No Roots - no Filters', { roots });
    return cy.elements();
  }

  //\\

  ////
  /// Outgoers from roots
  //  if there are no outgoing edges, there's just the root(s) as filter(s)
  //
  const outs = roots.outgoers().edges();

  // no edges scenario,
  // either several nodes without connection
  // or just one node in the filtergraph.
  if (outs.length === 0) {
    console.log('No outs exist');

    // unconnected roots scenario
    if (roots.length > 1) {
      // could be parent with one child.
      // or edge is missing.
      // connect the roots with AND
      // assign the edge to the filterCy
      // restart this function with the updated filterCy
      roots.map(root => console.log('No Edges, but several roots', { root }));
    }

    // curious if we traverse here many times
    console.log('We came by here now', { firstroot: roots.first() });

    /////
    //// Process Single(?) Root Node - ( just the first of the roots, check log above if there are several )
    ///  No-Edges Scenario
    //
    const tmp = processNodeOp(cy, roots.first(), '', {
      processed,
      pointer,
      fcy,
      cy,
    });

    console.log('This is the output of processNodeOp', { tmp });
    if (Object.keys(tmp.pointer).length > 1) {
      // not quite clear about the implication / reason
      throw new Error('Too many keys');
    }

    ////
    /// Return result
    //
    // look up result of the chosen root in pointer, processed.
    const loc = tmp.pointer[roots.first().data().id];

    // print result
    console.log('the res is here', { res: tmp.processed[loc], loc, rid: roots.first().data().id, tmpproc: tmp.processed, tmp });
    //\\

    return tmp.processed[loc];
  }

  /////
  //// We reach this point just in case there are outgoing edges
  ///  (ie outs.length !== 0)
  //
  //

  // we traverse here many times, eg in a parent - child scenario.
  // the trigger for the next iteration is inside processNodeOp function.
  // There the children of the parent are being executed, and they have a root as well.
  console.log('We came by there now', { firstroot: roots.first().data(), tr: new Error() });

  ////
  /// Now, as there are edges, there could be more roots
  //
  // Iterate over all existing roots
  // let res: cytoscape.Collection[] = roots.map(root => {
  const root = roots.first();

  console.log('The current Root we process here', { rootdata: root.data(), processed, pointer });
  ////
  /// Now we move into the Edges, as the edges create the logic.
  //  We adhere to the common rules AND > OR > XOR
  //

  // first all AND Edges (and their nodes) are processed
  let b = processNodeOp(cy, root, 'AND', { processed, pointer, fcy, cy });
  b.cy
    .elements('[type != "filter"]')
    .map((ele: any) =>
      console.log('The B Result for AND Logic', { rd: root.data(), ed: ele.data() }),
    );

  // then all OR Edges (and their nodes) are processed
  b = processNodeOp(cy, root, 'OR', b);
  b.cy
    .elements('[type != "filter"]')
    .map((ele: any) =>
      console.log('The B Result also containing OR Logic', { rd: root.data(), ed: ele.data() }),
    );

  // then all XOR Edges (and their nodes) are processed
  b = processNodeOp(cy, root, 'XOR', b);
  b.cy
    .elements('[type != "filter"]')
    .map((ele: any) =>
      console.log('The B Result also containing XOR Logic', { rd: root.data(), ed: ele.data() }),
    );

  Object.entries(b.pointer).map(([k, v]) =>
    console.log('1 Final Info: entries all pointer:', { rid: root.id(), k, v, sd: root.data() }),
  );
  Object.entries(b.processed).map(([k, v]) =>
    console.log('1 Final Info: entries all processed:', { rid: root.id(), k, v, sd: root.data() }),
  );
  Object.entries(b.processed).map(([k, v]) =>
    v.map(ele =>
      console.log('Final Info: entries all processed:', { rid: root.id(), sd: root.data(), k, ed: ele.data() }),
    ),
  );

  // the result for this root.
  const re = b.processed[b.pointer[root.id()]];
  if (typeof re !== 'undefined') {
    console.log( `This is the result for id: ${root.id()} it has ${re.length} entries` );
    // re.map(ele =>
    //   console.log('This is one Element of the Results', { ed: ele.data(), rd: root.data().select }),
    // );
  } else {
    console.log(`The result for id:  ${root.id()} doesnt exist`);
  }

  // prepare return
  let bp: cytoscape.Collection = cytoscape().collection();

  bp = Object.values(b.processed).reduce((acc, val) => {
    console.log('this is it now', { val });
    return val;
  }, bp);
  console.log('Returning bp return of root res', { root: root.data(), bp });

  // return bp
  const res = bp;
  // })

  let returnvalue: cytoscape.Collection;
  try {
    // returnvalue = res[0]
    returnvalue = res;
  } catch (error) {
    console.error('Error happened when reassigning Result', { res, error });
    returnvalue = cy.elements();
  }

  if (typeof returnvalue.select === 'function') {
    console.log('The reslt is ', { returnvalue, res });
  } else {
    console.log('The reslt is no collection', { returnvalue, res });
  }

  return returnvalue;
};

export const testOnly_nextones = (
  fcy: cytoscape.Collection,
  cy: cytoscape.Core,
): cytoscape.Collection => {
  return nextones(fcy, cy);
};

/**
 * prepares and executes Filtering
 * @param select
 * @param currentFilters
 * @param workaround
 * @param cy
 * @returns
 */
const filtering = (
  select: string | null,
  currentFilters: CyFilterCombo,
  cy: cytoscape.Core,
): cytoscape.Collection => {
  const cytoscape = require('cytoscape')

  // prints Filter Elements which are being used to filter.
  ;[
    ...Object.values(currentFilters.nodes),
    ...Object.values(currentFilters.edges),
  ].map(ele =>
    console.log('Filter This element is in current Filters', { ed: ele }),
  );

  // prints Cytoscape Elements which shall be filtered
  cy?.elements()?.map(ele =>
    console.log('This element is in cy', { select, ed: ele.data() }),
  );

  /////
  //// Preparation and building of a separate cy instance which
  ///  keeps the filters. `ficy`
  //
  // create a new cy instance which has the filters as graph
  // this enables graph operations given by cy.
  const clo = _.cloneDeep(currentFilters);

  ///
  // removes isparent and parent fields from node
  // adds info about parent to child nodes

  const cleansedNodes = Object.values(clo.nodes).map(node => {
    const { isparent, parent, ...rest } = node;
    const input: CyFilter = { ...rest };
    if (!isparent && parent) {
      input.parent = parent;
    }
    return { data: input };
  });

  console.log('The cleansed FilterNodes', { cleansed: cleansedNodes.map(ele => ele.data )});

  // the edges
  const cleansedEdges = Object.values(clo.edges).map(edge => ({ data: edge }));

  // array of filters {data: edge|node}
  const arrayCleansedEdgesAndNodes = [...cleansedNodes.concat(cleansedEdges)];

  const ficy: cytoscape.Collection = cytoscape({
    elements: arrayCleansedEdgesAndNodes,
  }).elements();

  console.log('Filter built and now part in ficy', { ficyelements : ficy.map(ele => ele.data() )});

  // Build the select string to node's data, if it's not a parent
  ficy
    .nodes()
    .filter(node => typeof node.data().col !== 'undefined')
    .map((node: cytoscape.NodeSingular) =>
      node.data('select', createFilterString(node)),
    );

  // not sure if thats still relevant since refactoring...
  // this is a helpful debug line in case some wrong nodes sneak in during development. delete once stable.
  // ficy.nodes().filter(ele => typeof ele.data().filterkey === 'undefined').map(ele => ficy.remove(ele))
  // console.log('These are ficy now, ', {ficy})

  // print filter cy elements
  console.log('This is one filter element from filtercy', { ficy: ficy.map(ele => ele.data() )});

  //\\\
  // \\\
  //  \\\

  ////////
  ////////
  ///////
  //////  Processing
  /////
  ////
  ///
  //
  //

  // Start processing
  const resu = nextones(ficy, cy);

  console.log('came by here before', { resu: resu.jsons() });
  console.log('what is here in the resu', { ed: resu.map(ele => ele.data() )});

  //\\\
  // \\\
  //  \\\

  return resu;
};



/**
 * transformer
 * moves from cyCollection into CyFilter Type
 *
 */
export const buildFiltersFromCyCollection = (
  cycoll: cytoscape.Collection,
): {
  nodes: { [key: string]: CyFilter }
  edges: { [key: string]: CyFilterEdge }
} => {
  const res: {
    nodes: { [key: string]: CyFilter }
    edges: { [key: string]: CyFilterEdge }
  } = {
    nodes: {},
    edges: {},
  };
  cycoll.nodes().map(node => {
    console.log('Processing this node', { nd: node.data() });
    const nd: cytoscape.NodeDataDefinition = node.data();
    const cn: CyFilter = {
      isparent: node.isParent(),
      parent: node.parent().length ? node.parent().data().id : null,
      id: nd?.id,
      label: nd?.label,
      comparison: nd?.comparison,
      value: nd?.value,
    };
    console.log('adding Processing this node', { cn, nd: node.data() });

    // Check if nd.id is defined
    if (nd.id !== undefined) {
      res.nodes[nd.id] = cn;
    } else {
      // Handle the case where nd.id is undefined
      console.error('nd.id is undefined');
    }
  });
  cycoll.edges().map(edge => {
    const ed: cytoscape.EdgeDataDefinition = edge.data();
    if (ed.id === undefined) {
      // Handle the case where nd.id is undefined
      console.error('nd.id is undefined');
    }

    const ce: CyFilterEdge = {
      id: ed.id as string,
      source: ed.source,
      target: ed.target,
      combinator: ed.combinator,
    };

    res.edges[ed.id as string] = ce;
  });
  return res;
};

export const testOnly_filtering = (
  select: string | null,
  currentFilters: {
    nodes: { [key: string]: CyFilter }
    edges: { [key: string]: any }
  },
  cy: cytoscape.Core,
): cytoscape.Collection => {
  return filtering(select, currentFilters,  cy);
};

// Function to create a CY filter string from a node
// TODO sophisticated selects like negate, isset, is null ...
const createFilterString = (node: cytoscape.NodeSingular) =>
  `[${node.data().label} ${node.data().comparison} "${node.data().value}"]`;

///////

// Function to check if a node is a leaf
const isLeaf = (
  node: cytoscape.NodeDataDefinition,
  edges: cytoscape.EdgeDataDefinition[],
) =>
  !edges.some((edge: cytoscape.EdgeDataDefinition) => edge.source === node.id); // Checks if the node has no outgoing edges (is a leaf)

// Recursive function to build the query
// const buildQuery = (node: cytoscape.NodeSingular, nodes:cytoscape.NodeCollection, edges: cytoscape.EdgeDataDefinition[]) : string=> {
//     if (isLeaf(node.data(), edges)) {
//         return createFilterString(node); // If node is a leaf, return its filter string
//     }

//     // Find all edges starting from this node
//     const filteredEdges = edges.filter(edge => edge.source === node.id);
//     if (filteredEdges.length === 1) {
//         // If there is only one edge, return a query combining the current node and the connected node
//         const connectedNode = nodes.find(n => n.id === filteredEdges[0].target);
//         return `${createFilterString(node)} ${filteredEdges[0].combinator} ${buildQuery(connectedNode, nodes, edges)}`;
//     } else {

//         // If multiple edges, handle accordingly (may need more logic here based on your specific graph structure)
//         // For now, returning just the current node's filter string
//         return createFilterString(node);
//     }
// };

// // Function to split and execute queries
// const executeQueries = (node, nodes, edges, cy) => {

//     const query = buildQuery(node, nodes, edges); // Build the full query for the given node
//     console.log("this is the restul query ", {query})

//     // Extract filters and operators
//     const parts = query.match(/\[.*?\]|\bAND\b|\bOR\b/g);

//     // Process ANDs first to respect precedence

//     const andProcessed = parts.reduce((acc, part, index, array) => {
//         if (part === 'AND') {
//             let lastFilter = acc.pop();
//             let nextFilter = array[index + 1];
//             acc.push(`${lastFilter}${nextFilter}`);
//             array[index + 1] = ''; // Mark as processed
//         } else if (part !== '' && part !== 'OR') {
//             acc.push(part);
//         }
//         return acc;
//     }, []);

//     console.log('and processed', {andProcessed})
//     // Now process ORs and standalone filters
//     const finalQueries = [];
//     const combine = [];
//     let currentQuery = '';
//     andProcessed.forEach((part, index) => {
//         if (part === 'OR') {
//             finalQueries.push(currentQuery);
//             combine.push({
//                 first: finalQueries.length - 1,
//                 second: finalQueries.length,
//                 comb: 'OR'
//             });
//             currentQuery = '';
//         } else {
//             currentQuery = currentQuery ? `${currentQuery},${part}` : part;
//         }
//     });
//     if (currentQuery) finalQueries.push(currentQuery);

//     console.log("final queries: ", finalQueries);
//     console.log("combine structure: ", combine);

//     // Execute each query part
//     // finalQueries.forEach(queryPart =>
//     let aa =  exec(finalQueries[0], cy)
//     console.log("this is the restul 1 ", {aa});
// return aa
//       // );

//     // Additional logic to handle the combination of results based on 'combine' structure goes here

//     // return { finalQueries, combine };
// };

// const exec = (str, cy, a) => console.log("this is the restul subs ", {a,str, fin: cy.$(str).jsons()});
// const exec = (str, cy) => cy.$(str);

// // Function to find root nodes (nodes with no incoming edges)
// const findRootNodes = (nodes, edges) => nodes.filter(node => !edges.some(edge => edge.target === node.id));

///////

export const filterCy = (
  cy: cytoscape.Core,
  currentFilters: {
    nodes: { [key: string]: CyFilter }
    edges: { [key: string]: any }
  },
): {
  nodes: { [key: string]: CyFilter }
  edges: { [key: string]: any }
} => {

  const filters_currently_in_cy = cy.$(':visible[type = "filter"]');
  console.log( 'The filters visible in Cy, vs current Filters from graphfilters', { filters_from_cy: filters_currently_in_cy, currentFilters } );

  const currentFilters2 = buildFiltersFromCyCollection(filters_currently_in_cy);
  // const currentFilters2 : CyFilterCombo = {
  //   nodes: { },
  //   edges: { }
  // };

  const res = filtering(null, currentFilters2, cy);

  ////
  ///
  // results of filtering process
  // if there's no result, then no need to change the graph
  if (res === null || res.length === 0) {
    console.log('current filters at early exit')
    console.trace('current filters at early exit', { currentFilters });
    return currentFilters;
  }else{
    console.log('The results provided from Filtering', {currentFilters, filterresults: res.map(ele => ele.data())})
  }

  // the filters have been applied successfully - now
  cy.$(':selected').unselect();
  //const  =
  // cy.elements().style('display','element')
  
  const keeping = res.select(); // results from filter
  console.log('Res is This', { filterResult: res.map(ele =>  ele.data() )});
  console.log('Keeping is This', { ed: keeping.map(ele =>  ele.data() )});

  const structure = cy.filter('[type = "structure"]').select();
  console.log('Structure is', { structureElement: structure.map(ele => ele.data() )});

  const filters = cy.filter('[type = "filter"]').select();
  console.log('Filter is', { filterElement: filters.map(ele => ele.data() )});

  // take the undesired nodes out of the graph-display
  const stay = structure.union(filters).union(keeping);
  console.log('This will stay', { ed: stay.map(ele => ele.data() )});
  // console.log('arrived in filter filtered', {select, structure, keeping});

  // reset the selection, empty the graph.
  cy.elements().map(ele => ele.style('display', 'none').unselect());
  console.log('This is Visible - should be none', { es: cy.elements(':visible').map(ele => ele.style('display') )}),
  

  // set the elements from `stay` collection visible again
  cy.elements()
    .filter(
      (ele: cytoscape.SingularElementArgument) =>
        stay.getElementById(ele.data().id).length > 0,
    )
    .map(ele => ele.style('display', 'element'));

  
  console.log('This is Visible and thats ok', { es: cy.elements(':visible').map(ele => ele.data() )}),
  
  cy.fit();

  return currentFilters;
};

// searches in cy
// flashes results in graph
export const search = (cy: cytoscape.Core, searchstring: string) => {
  console.log('current debug: the search is triggered', cy);

  console.log('The CyRef Here 2', cy, 'curr', cy);

  console.log('the search happens now', searchstring);
  const structure = cy.elements('[type = "structure"]');
  const found = cy.elements(
    `[label *= "${searchstring}"], [description *= "${searchstring}"]`,
  );

  const old_pan = cy.pan();
  const old_zoom = cy.zoom();
  cy.fit(found.union(structure));

  found.flashClass('neighbourhood', 5000);

  // workaround, css for hidden settings not known
  // also resets view before search
  cy.elements().not(found).not(structure).css('display', 'none');
  setTimeout(() => {
    cy.elements().not(found).not(structure).css('display', 'element');
    cy.zoom(old_zoom);
    cy.pan(old_pan);
  }, 5000);

  return true;
};
