import { configureStore, ThunkAction, Action, applyMiddleware } from '@reduxjs/toolkit';

import type { EnhancedStore, PreloadedState } from '@reduxjs/toolkit';

// DevTools: Browser-Devtools for Redux, configured for Redux Store below
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootReducer } from './rootReducer';
import { rootState } from './rootState';
// import deepFreeze from 'deep-freeze'
import { Middleware } from '@reduxjs/toolkit';

// Middleware to freeze state after each action
// const freezeStateMiddleware: Middleware = store => next => action => {
//   const result = next(action);
//   deepFreeze(store.getState().uiApp);
//   // store.getState().uiApp = null
//   return result;
// };

export const setupStore = (
  preloadedState?: PreloadedState<RootState>,
  // middleware: Middleware[] = [],
  middleware?: Array<any>,
): EnhancedStore<RootState> => {
  let store: EnhancedStore<RootState>;
  if (process.env.NODE_ENV === 'production') {
    store = configureStore({
      reducer: rootReducer,
      preloadedState,
      middleware,
    });
    return store;
  } else {
    // const composedMiddleware = [freezeStateMiddleware, ...middleware];

    // enable composeWithDevTools only for development environment
    // https://codeburst.io/redux-devtools-for-dummies-74566c597d7
    // const composeEnhancers = composeWithDevTools({
    //   trace: true,
    //   traceLimit: 25,
    // });


    composeWithDevTools(
      applyMiddleware(...middleware ?? []),
      // other store enhancers if any
    ),

    // deepFreeze(preloadedState);
    store = configureStore({
      reducer: rootReducer,
      preloadedState,
      // middleware: composedMiddleware,
      middleware,
      // enhancers: [composeEnhancers],
    });
    return store;
  }

};

// @ts-ignore
export type RootState = ReturnType<typeof rootState>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>;
