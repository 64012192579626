import { ofType, StateObservable } from 'redux-observable';
import * as operators from 'rxjs';
import * as _ from 'lodash';
// import fetch from 'node-fetch';
import * as helper from '../helper';
import { NeoGraph, reconstructNode } from '../api/neo4jhelper';
import {
  types as uiActionTypes,
  initialState as uiInitialState,
} from './uiDuck';
import { getApiBaseUrl } from '../api/url';
import { RootState } from '../rootState';
import { Action, AnyAction, Dispatch } from 'redux';
import { Observable } from 'rxjs';
import { Node, Relationship } from 'neo4j-driver';



const name = 'user';
export const types = {
  NAME: `${name}`,
  UPDATE_WORKSPACE: `${name}/UPDATE_WORKSPACE`,
  UPDATE_WORKSPACE_FROM_DB: `${name}/UPDATE_WORKSPACE_FROM_DB`,
  WORKSPACE_NEO: `${name}/WORKSPACE_NEO`,
  SECURITY: `${name}/SECURITY`,
  SECURITY_NEO: `${name}/SECURITY_NEO`,
  ADD_CATEGORY_ERROR: `${name}/ADD_CATEGORY_ERROR`,
};

// selector functions
export const select = {
  workspace: (state: RootState) => state[`${name}App`].workspace,
  security: (state: RootState) => state[`${name}App`].security,
  // security: state => state[`${name}App`].security,
};

export const dispatcher = {
  postWorkspace: (dispatch: Dispatch, value: Object) => {
    console.trace('dispatching action to post workspace now', value);
    const bla = actions.workspace({ method: 'post', value });
    console.log('CypherP action', bla);
    dispatch(bla);
  },
  getWorkspace: (dispatch: Dispatch, value: Object) => {
    console.log('dispatching action to get workspace now', value);
    const bla = actions.workspace({ method: 'get', value });
    console.log('Get CypherP action', { bla, typeis: types.WORKSPACE_NEO });
    if (typeof bla === 'undefined' || typeof bla.type === 'undefined') {
      console.log('undefined Get CypherP action', { bla, typeis: types.WORKSPACE_NEO });
      return;
    }
    dispatch({ type: bla.type, payload: bla.payload });
  },
  postSecurity: (dispatch: Dispatch, value: Object) => {
    const bla = actions.security_neo({ method: 'post', value });
    console.log('dispatching action to p Security  now', { value, bla });
    dispatch(bla);
  },
  getSecurity: (dispatch: Dispatch, value: Object) => {
    console.log('dispatching action to g Security  now', value);
    dispatch(actions.security_neo({ method: 'get', value }));
  },
  security: (dispatch: Dispatch, value: Object) => {
    dispatch(actions.security(value));
  },
};

// action creator
export const actions = {
  update_workspace: (value: Object) => {
    console.log('updateWorkspace here', value);
    return { type: types.UPDATE_WORKSPACE, payload: value };
  },
  workspace: (value: Object) => {
    console.log('Perform Workspace Action here', { type: types.WORKSPACE_NEO, payload: value });
    return { type: types.WORKSPACE_NEO, payload: value };
  },
  security_neo: (value: Object) => {
    console.log('Perform Security Action here', value);
    return { type: types.SECURITY_NEO, payload: value };
  },
  security: (value: Object) => {
    return { type: types.SECURITY, payload: value };
  },
};

// reducer's initial state

export interface InitialState {
  workspace: {
    filter: helper.CyFilterCombo[]
  }
  security:
  | { iv: string; salt: string; password: string; keys: Object }
  | Object
}

export const initialState = {
  workspace: { filter: [] },
  security: {},
};

export default function reducer(state = initialState, action: AnyAction): InitialState {
  switch (action.type) {
    case types.ADD_CATEGORY_ERROR:
      console.trace('Error Handling', action, state);
      console.error('Error Handling', action, state);
      throw action.payload;
    // return { ...state };
    case types.UPDATE_WORKSPACE_FROM_DB:
      console.trace('Cypher udws we are updating the workspace from db', action);
      console.log('Cypher udws we are updating the workspace from db', action);

      const tmp = {
        ...state,
        workspace: action.payload,
      };
      console.log('the new ws', tmp);
      return tmp;
    case types.UPDATE_WORKSPACE:
      console.log('log Cypher we are updating the workspace', action);
      console.trace('Cypher we are updating the workspace', action);
      return {
        ...state,
        //Update Workspace is called from some epics, ensure that they can simply provide their content
        //and other content isn't affected
        workspace: { ...state.workspace, ...action.payload },
      };
    case types.SECURITY:
      console.trace('Cypher we are updating the Security', action);
      return {
        ...state,
        security: action.payload,
      };
    case types.WORKSPACE_NEO:
      console.trace('Cypher we are updating the Workspace Neo', action);
      return {
        ...state,
      };
    default:
      return state;
  }
}

/**
 * Asynchronous function to post a workspace to the API.
 * @param {object} action - The action object containing the payload.
 * @returns {Promise<any>} - A promise that resolves to the content of the response if successful, or throws an error otherwise.
 */
async function workspaceAction(
  action: AnyAction = {type: null, payload: null}
): Promise<any> {
  /**
   * Fetches data from the API.
   * @param {object} action - The action object containing the payload.
   * @returns {Promise<any>} - A promise that resolves to the content of the response if successful, or throws an error otherwise.
   */
  const fetchData = async (  
    action: AnyAction = {type: null, payload: null}
  ): Promise<any> => {
    try {
      console.log(`${action.payload.method} Workspace Api`, action.payload);

      const requestOptions: RequestInit = {
        method: action.payload.method.toUpperCase(),
        headers: {
          'Content-Type': 'application/json',
        },
      };

      if (requestOptions.method === 'POST') {
        requestOptions.body = JSON.stringify(action.payload.value); // Only stringify the value part of the payload
      }
      console.log('Workspace Api will fetch', requestOptions);

      let res;
      try {
        res = await fetch(
          // `${getApiBaseUrl()}/api/neo/workspace`,
          '/api/neo/workspace',
          requestOptions,
        );
      } catch (error) {
        console.log('Workspace Api did catch', { url: `${getApiBaseUrl()}/api/neo/workspace`, error });
      }

      console.log('Workspace Api did not catch', { res, url: `${getApiBaseUrl()}/api/neo/workspace` });

      if (typeof res === 'undefined') {
        // Handle Fetch errors
        console.error( `${action.payload.method} Workspace Api Failed - No Result`, { a: typeof fetch } );
        throw new Error('Fetch Result Error');
      }
      if (!res.ok) {
        // Handle HTTP errors
        console.error(`${action.payload.method} Workspace Api Failed`, { res, payload: action.payload });
        throw new Error(`HTTP Error: ${res.status} ${res.statusText}`);
      }
      console.log(`${action.payload.method} Workspace Api Success`, { res, payload: action.payload });

      const json = await res.json();
      console.log('API Response here:', JSON.stringify(json)); // Log the API response
      if (json.content) {
        return json.content;
      } else {
        // Handle API response format errors
        throw new Error('Invalid API response format');
      }
    } catch (error) {
      console.error('Error in fetchData:', error);
      throw error; // Rethrow the error to propagate it
    }
  };

  try {
    console.log('Wait in workspaceAction:', { action });
    const result = await fetchData(action);
    console.log('Success in workspaceAction:', { action, result });
    return result;
  } catch (error) {
    console.error('Error in workspaceAction:', error);
    throw error; // Rethrow the error to propagate it
  }
}

/**
 * Asynchronous function to post a workspace to the API.
 * @param {object} action - The action object containing the payload.
 * @returns {Promise} - A promise that resolves to the content of the response if successful, or undefined otherwise.
 */
async function securityAction(
  action: AnyAction = {type: null, payload: null}
): Promise<any> {
  /**
   * Fetches data from the API.
   * @param {object} action - The action object containing the payload.
   * @returns {Promise} - A promise that resolves to the content of the response if successful, or undefined otherwise.
   */
  const fetchData = async (  
    action: AnyAction = {type: null, payload: null}
  ): Promise<any> => {
    console.log( `${action.payload.method} Security Api`, action.payload, JSON.stringify(action.payload) );

    // const res = await fetch('/api/neo/neo4j', {method: "GET"});
    const requestOptions: RequestInit = {
      method: action.payload.method,
    };

    if (action.payload.method.toUpperCase() === 'POST') {
      requestOptions.body = JSON.stringify(action.payload);
    }

    try {
      const res = await fetch('/api/neo/security', requestOptions);
      console.log('CypherL result of Call Security', res);
      if (res.status >= 400) {
        console.log('Error Calling CypherL result of Call Security', { res });
        // throw new Error(res.statusText)
        return await Promise.reject(new Error(res.statusText));
      }
      const json = await res.json();
      if (json.content) {
        console.log('JSON CypherL result of Call Security', { json, res });
        return json.content;
      }
    } catch (error) {
      console.error('Error JSON CypherL result of Call Security', { error });
    }

  };

  // Call the fetchData function and return the result
  return fetchData(action);
}

export const epics = {
  workspace: (action$: Observable<Action>, store: StateObservable<RootState>) =>
    action$.pipe(
      /**
       * Filters incoming actions to only process WORKSPACE_NEO actions.
       * This ensures that only relevant actions trigger the epic.
       */

      operators.filter(action => action.type === types.WORKSPACE_NEO),

      /**
       * Logs the start of processing for debugging purposes.
       * The tap operator allows side effects such as logging without affecting the stream.
       */
      operators.tap(action =>
        console.log('Cypher Epic Start Workspace Action', { action, store }),
      ),

      /**
       * Switches to a new observable created by the workspaceAction function.
       *
       * switchMap cancels any ongoing observable when a new action comes in, ensuring only the latest action is processed.
       * This is particularly useful when dealing with asynchronous operations like network requests.
       *
       * Example: If multiple WORKSPACE_NEO actions are dispatched rapidly, switchMap will only allow the most recent
       * one to proceed by canceling the previous ones.
       */
      operators.switchMap(action => {
        console.log('Cypher Epic switchMap initiated with action:', action);
        return operators.from(workspaceAction(action)).pipe(
          // return workspaceAction(action).pipe(
          /**
           * Processes the result of workspaceAction by logging nodes and edges for debugging.
           *
           * The tap operator is used here for logging the intermediate results without modifying the stream.
           */
          operators.tap((result: NeoGraph) => {
            console.log('Cypher Epic has result', result);
            
            // Iterate over nodes (n) with both key (ID) and value (Node)
            Object.entries(result.n).forEach(([id, node]: [string, Node]) => console.log(`has node with ID ${id}`, node));
            Object.entries(result.r).forEach(([id, edge]: [string, Relationship]) => console.log(`has edge with ID ${id}`, edge));

            return result;
          }),

          /**
           * Handles the reconstruction of the workspace and creates parallel actions.
           *
           * mergeMap allows multiple inner observables to run concurrently and merge their results into the output observable.
           * This is used here to handle multiple asynchronous operations such as network requests or complex computations.
           */
          operators.mergeMap(result => {
            try {
              console.log('Cypher Epic before reconstructing', { result });

              // Initial payload with default filter and settings
              let payload = { filter: uiInitialState.filter, settings: {} };

              // If there are nodes or edges, reconstruct the graph
              if (Object.keys(result.n).length > 0 || Object.keys(result.r).length > 0) {
                console.log('Cypher Epic will be reconstructing', { result });
                const graph = reconstructNode(result);
                console.log('Cypher Epic done reconstructing', graph);

                // Ensure the payload format is correct
                if (graph.root) {
                  payload = { ...payload, ...graph.root };
                } else {
                  throw new Error('Payload not in expected format');
                }
              }

              // Adjust node positions in the filter to ensure they are integers
              payload.filter = Object.values(payload.filter).map(index => {
                console.log('GraphFilterPayload Index', { index });
                const adjustedNodes = Object.fromEntries(
                  Object.entries(index.nodes).map(([key, node]) => {
                    console.log('GraphFilterPayload node', { node });

                    if (node.position) {
                      node.position = {
                        x: Math.ceil(node.position.x),
                        y: Math.ceil(node.position.y),
                      };
                    }
                    return [key, node];
                  }),
                );
                console.log('GraphFilterPayload adjusted', { edges: index.edges, nodes: adjustedNodes });

                return { edges: index.edges ?? {}, nodes: adjustedNodes };
              });

              console.log('GraphFilterPayload before backend addfilter', { payloadFilter: payload.filter });

              // Create the filter action
              const addFilterAction = {
                type: uiActionTypes.BACKEND_ADDFILTER,
                payload: payload.filter,
              };
              const addFilterParallelActions$ = operators.of(addFilterAction);

              // Create the update workspace action
              const updateWorkspaceAction = {
                type: types.UPDATE_WORKSPACE_FROM_DB,
                payload,
              };
              const updateWorkspaceParallelActions$ = operators.of(
                updateWorkspaceAction,
              );

              // Combine actions into a single observable
              let parallelActions$;
              if (payload.filter) {
                // If there are filters, create a sequence of actions for each filter
                const turnpageParallelActions$ = operators
                  .from(payload.filter)
                  .pipe(
                    operators.concatMap((page, idx) =>
                      operators
                        .of({ type: uiActionTypes.TURNPAGE, payload: idx })
                        .pipe(operators.delay(0)),
                    ),
                  );

                // Concatenate all parallel actions into one observable sequence
                parallelActions$ = operators.concat(
                  updateWorkspaceParallelActions$,
                  turnpageParallelActions$,
                  addFilterParallelActions$,
                );
              } else {
                // If no filters, only include the workspace update and add filter actions
                parallelActions$ = operators.concat(
                  updateWorkspaceParallelActions$,
                  addFilterParallelActions$,
                );
              }

              /**
               * Log the combined observable sequence for debugging.
               * Return the combined observable sequence to be executed in parallel.
               */
              console.log('Cypher Epic parallel sequential pipe results', { parallelActions$ });
              return parallelActions$;
            } catch (error) {
              /**
               * Log the error and return an empty observable in case of failure.
               * Returning operators.EMPTY ensures that the stream completes without emitting further actions.
               */
              console.log('Cypher Epic Failed Error', { error });
              return operators.EMPTY;
            }
          }),

          /**
           * Handles errors by catching them and dispatching an error action.
           *
           * catchError intercepts errors in the observable chain and allows us to return a new observable.
           * In this case, it returns an observable of an ADD_CATEGORY_ERROR action with the error payload.
           */
          operators.catchError(error => {
            console.error('Cypher Epic Workspace Action Failed!', error.message);
            return operators.of({
              type: types.ADD_CATEGORY_ERROR,
              payload: error,
            });
          }),
        );
      }),
    ),
  security: (action$: Observable<Action>, store: StateObservable<RootState>) =>
    action$.pipe(
      operators.filter(action => action.type === types.SECURITY_NEO),
      operators.tap(a => console.log('Cypher Epic Start Security Action', a)),
      // operators.switchMap((action) => {
      //   // switchmap allows switching the mapping from the input to another observable which is created in here
      //   const response = securityAction(action)
      //   // creating the new observable
      //   return response;
      // }),
      operators.switchMap(async action => {
        console.error('befroe Another Cypher Epic Security Action Failed!', {});
        try {
          console.log( 'Still before Another Cypher Epic Security Action Failed!', {} );

          const response = await securityAction(action);

          console.log('now after Another Cypher Epic Security Action Failed!', { response });

          return response;
        } catch (error) {
          console.error('Another Cypher Epic Security Action Failed ! ', { error });
          // You can return a fallback value or throw a new error if needed
          // return of(fallbackValue);

          throw error;
          return operators.of({ type: types.ADD_CATEGORY_ERROR, payload: error });
        }
      }),
      operators.tap(a =>
        console.log('Cypher Epic Performed Security Action', a),
      ),
      operators.map(a => ({ type: types.SECURITY, payload: a.data })),
      operators.catchError(err => {
        console.error('Cypher Epic Security Action Failed!', err.message);
        return operators.of({ type: types.ADD_CATEGORY_ERROR, payload: err });
      }),
    ),
};
