// @ts-nocheck
// @ts-ignore
import React from 'react';
import { connect } from 'react-redux';
import * as jsondiffpatch from 'jsondiffpatch';
import * as jshelper from '../helper';
import * as backendDuck from '../ducks/backendDuck';
import * as uiDuck from '../ducks/uiDuck';
import { Dispatch } from 'redux';

export const extract_cy_elements = cy => cy.elements(':visible');

export const dispatcher = {
  update_nodes: (dispatch, value) => {
    console.log('dispatching action to updating the left now - update_theleft');
    dispatch(backendDuck.actions.update_nodes(value));
  },
  update_edges: (dispatch, value) => {
    dispatch(backendDuck.actions.update_edges(value));
  },
};

// export const filter = (dispatch) => {
//
//      // filtering specifies elements which are now visible (and those which arent)
//	  // the visible ones are sent to store
//	  const elesingraph = extract_cy_elements(cyRef.current).jsons()
//	  console.log("ele in mapper before", elesingraph);
//	  const newnodes = elesingraph.filter(ele => ele.group === 'nodes').map((ele)=>{
//		const ele_props = ele.data;
//		const ret = {...ele_props };
//		return ret;
//	  });
//	  const newedges = elesingraph.filter(ele => ele.group === 'edges').map((ele)=>{
//		const ele_props = ele.data;
//		const ret = {...ele_props };
//		return ret;
//	  });
//	  const neweles = {nodes: newnodes, edges: newedges};
//	  dispatcher.update_visible(dispatch, neweles);
//	  console.log('the elements in graph are:', neweles);
// }
//

/**
 *
 * dispatches node update if something changed
 * @param data neighbourhood_id
 *
 */
export const highlight = (cy, dispatch, data) => {
  console.log('updating the left now - update_theleft');
  console.trace();
  if (typeof data === 'undefined') return;
  if (typeof data === 'undefined') return;
  if (typeof data.nodes === 'undefined') return;
  if (typeof data.edges === 'undefined') return;

  console.log('still updating the left now', data);
  const alt = {
    nodes: jshelper.object_to_array(Object.values(data.nodes)),
    edges: jshelper.object_to_array(Object.values(data.edges)),
  };

  // var neu = cy.json().elements;

  // //var delta = jsondiffpatch.diff(alt, neu);
  // //var ret = jsondiffpatch.patch(alt, delta);

  // var delta = jsondiffpatch.diff(alt, neu);
  // var ret = jsondiffpatch.patch(alt, delta);

  // console.log(' and json 42 alt', alt);
  // console.log(' and json 42 neu', neu);
  // console.log(' and json 42 del', delta);
  // console.log(' and json 42 ret', ret);

  // dispatcher.update_nodes(dispatch, ret);
  dispatcher.update_nodes(dispatch, alt);
};

/**
 *
 * dispatches node update if something changed
 * @param data
 *
 */
export const update_theleft = (cy: cytoscape.Core, dispatch: Dispatch, data: {nodes: cytoscape.NodeDefinition[], edges: cytoscape.EdgeDefinition[]}) => {
  console.log('updating the left now - update_theleft', { data });
  console.trace();
  if (typeof data === 'undefined') return;
  if (typeof data === 'undefined') return;
  if (typeof data.nodes === 'undefined') return;
  if (typeof data.edges === 'undefined') return;

  console.log('still updating the left now', data);
  const alt = {
    nodes: jshelper.object_to_array(Object.values(data.nodes)),
    edges: jshelper.object_to_array(Object.values(data.edges)),
  };

  console.log('still updating the left now alt', { alt, data });
  // dispatcher.update_nodes(dispatch, ret);

  console.log('the data for update the left 3', { data: alt });
  uiDuck.dispatcher.update_theleft(dispatch, alt);
  dispatcher.update_nodes(dispatch, alt);
};

/**
 *
 * dispatches node update if something changed
 * @param data
 *
 */
export const show_meta = (dispatch, data) => {
  console.log('updating the left now - update_theleft');
  console.trace();
  if (typeof data === 'undefined') return;
  if (typeof data === 'undefined') return;
  if (typeof data.nodes === 'undefined') return;
  if (typeof data.edges === 'undefined') return;

  console.log('still updating the left now', data);
  const alt = {
    nodes: jshelper.object_to_array(Object.values(data.nodes)),
    edges: jshelper.object_to_array(Object.values(data.edges)),
  };

  const neu = cy.json().elements;

  // var delta = jsondiffpatch.diff(alt, neu);
  // var ret = jsondiffpatch.patch(alt, delta);

  const delta = jsondiffpatch.diff(alt, neu);
  const ret = jsondiffpatch.patch(alt, delta);

  console.log(' and json 42 alt', alt);
  console.log(' and json 42 neu', neu);
  console.log(' and json 42 del', delta);
  console.log(' and json 42 ret', ret);

  dispatcher.update_nodes(dispatch, ret);
};
