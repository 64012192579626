import { Dispatch } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import * as helper from '../helper';
import * as neo4jhelper from '../api/neo4jhelper';
import * as graphhandling from './handling';
import * as backendDuck from '../ducks/backendDuck';
import * as userDuck from '../ducks/userDuck';
import * as uiDuck from '../ducks/uiDuck';
import cytoscape, {
  EdgeDefinition,
  EdgeSingular,
  ElementDataDefinition,
  ElementDefinition,
  ElementsDefinition,
  NodeDefinition,
  NodeSingular,
} from 'cytoscape';
import * as _ from 'lodash';
import { FilterChain, FilterChainElement } from '../../types/types';
import Ajv, { JSONSchemaType } from 'ajv';

// const getCommonProperties = (aggregate: { [key: string]: string}, inputArray: any[]) => {
//   console.log('input and aggregate',{aggregate, inputArray})
//   return inputArray.reduce((result, obj) => {
//     console.log('input and aggregate 2',{result, obj})
//     return Object.entries(result)
//       .filter(([key, value]) => key in obj && obj[key] === value)
//       // .every(val => {
//       //   console.log('input and aggregate this is the value provided', val)
//       //   return val})
//       .reduce((acc, [prop, val]) => {
//         console.log('input and aggregate this is the inner', {acc, prop, val})
//         return { ...acc, [prop]: val }
//       }, {})
//       // .every(val => console.log('input and aggregate this is the final inner reduced', val))
//   }, aggregate);
// };


// retrieves an array of definitions
// returns a single definition which contains all common properties of that array
export const getCommonProperties = (
  aggregate: cytoscape.ElementDataDefinition,
  inputArray: cytoscape.ElementDataDefinition[],
): cytoscape.ElementDataDefinition => {
  // returns the result of the inner operation without furhter ado
  // thus returns a fresh single object each iteration (see inner reduce comment)
  console.trace('This is input array',{type: typeof inputArray, inputArray, isarray: _.isArray(inputArray)})
    
  return inputArray.reduce(
    (result, obj) =>
      // returns an object which gets constructed by comparison between objects from the outer operation
      // and the prefilled aggregate, which is used as acc 'result'.
      Object.entries(result)
        .filter(([prop, val]) => prop in obj)

        // starts each time with an empty object and finally this (by then filled) object
        // will be passed to the outer reduce, where it will be replacing the outer acc.
        // during the next outer array's iteration, the inner iteration over the outer acc starts again
        // this time, that very `outer acc` contains the acc, which has been created here during the last iteration
        // thus, the outer acc starts with a relatively large set and will shrink (or stay) during each iteration.
        .reduce(
          (acc, [prop, val]) => {
            console.log('this is the obj', { obj, prop, val });
            return {
              ...acc,
              [prop]: obj[prop] === val ? val : null,
            };
          }, 
          // ({
          //   ...acc,
          //   [prop]: obj[prop] === val ? val : null,
          // }),
          {},
        ),
    aggregate,
  );
}


// provides an object which contains all values which are identical
// across all objects from meta
export const buildCommonValues = (
  meta: cytoscape.ElementDataDefinition[],
) : { [k: string]: uiDuck.field_type } => {
  // we prefill commonvalues with the first entry of meta array (if it exists)
  if (
    !Array.isArray(meta) ||
    meta.length == 0 ||
    typeof meta[0] === 'undefined'
  ) {
    console.log('input and aggregate meta has no entries', { meta });
  }

  console.log('passing by here too - build commonvalues', { meta });

  // initialize the getCommonProperties function with the first entry of meta
  // as ... else the typerequirement for getCommonProperties is broken
  let commonvalues = meta[0] as cytoscape.ElementDataDefinition ;

  console.log('these are the common values now', { meta, commonvalues });

  commonvalues = getCommonProperties(commonvalues, meta);

  console.log('input and aggregate the result is here now', { meta, commonvalues });

  // let newcommonvalues: {[key: string]: uiDuck.field_type} = {}
  const newcommonvalues: { [key: string]: uiDuck.field_type } = {};
  console.log('the common values are start', { meta, commonvalues, newcommonvalues });
  Object.entries(commonvalues)
    .filter(([k, v]) => v !== null && !Array.isArray(v) && typeof v === 'object' && 'type' in v )
    .map(([k, v]) => {
      if ('type' in v && 'values' in v && 'checkIfValid' in v ){
        console.log('xxxxxy its an ojbect ', { k, v });
        return (newcommonvalues[k] = v as uiDuck.field_type );
      }
    });

    console.log('the common values here are',{commonvalues, entries : Object.entries(commonvalues)})
  Object.entries(commonvalues)
    .filter(([k, v]) => Array.isArray(v) || typeof v === 'string')
    .map(([k, v]) => {
      if (Array.isArray(v) && v.some(ele => typeof ele !== 'string')) {
        throw new Error('Only String allowed in Array');
      }
      console.log('xxxxxy its a string ', { k, v, commonvalues });
      newcommonvalues[k] = {
        type: 'textfield',
        checkIfValid: () => true,
        values: { default: '', selected: v as string },
      };
    });

  Object.entries(newcommonvalues).map(([k, v]) =>
    console.log('This is the newcommon kv', { k, v }),
  );

  const othernewcommonvalues = Object.fromEntries(
    Object.entries(newcommonvalues).filter(([k, v]) => v !== null),
  );
  return othernewcommonvalues
};


/**
 *
 * from graph cy,
 * here we process the filter elements which changed in the graph
 * then we dispatch: uiDuck.dispatcher.filters(dispatch, filters)
 * @param cy
 * @param dispatch
 * @param filterElements
 */
const processFilters = async (
  cy: cytoscape.Core,
  dispatch: Dispatch<any>,
  filterElements: {
    nodes: helper.CyFilter[]
    edges: helper.CyFilterEdge[]
  },
  dropfilter: boolean = false
): Promise<any> => {

  if(dropfilter){

    // just to delete filter
    // in this case, the filterElements are the ones which shall be deleted
    let combo : helper.CyFilterCombo = {nodes: {}, edges: {}} 
    combo = filterElements.nodes.reduce((acc,node) => ({edges: {...acc.edges}, nodes: { ...acc.nodes, [node.id! as string]: node }}), combo)
    console.log('This is fino1', {combo, filterElements})
    combo = filterElements.edges.reduce((acc,edge) => ({nodes: {...acc.nodes}, edges: { ...acc.edges, [edge.id! as string]: edge }}), combo)

    console.log('This is fino2', {combo, filterElements})
    
    return uiDuck.dispatcher.filters(dispatch, {crud: '___d', storageFilterCombo: combo});
  }

  console.log('the input for filters', { elements: filterElements, stack: new Error() });
  const filters: helper.CyFilterCombo = { nodes: {}, edges: {} };
  if (filterElements == null) {
    filterElements = { nodes: [], edges: [] };
  }

  // throw new Error('Proceed here, check why parents are type boolean, consider storing the parent`s id instead')
  const parents: { [key: string]: string } = {};

  console.log('this is the child nodes', { nodes: filterElements.nodes });
  filterElements.nodes
    .filter(node => node.isparent)
    .map(child => {
      console.log('this is the child child', { child });
      return (parents[child.parent as string] = 'true');
    });

  // we're here in a flow from graphCy to graphFilters,
  // therefore we convert cy-node data into CyFilter Data
  filterElements.nodes.map(ele => {
    console.log('What is ele here?', { ele, type: typeof ele });
    if (typeof ele.id === 'undefined') {
      throw new Error('NodeElement has no ID');
    }
    filters.nodes[ele.id] = {
      id: ele.id,
      isparent: parents[ele.id] ? true : false, // Changed to isParent to match type definition
      parent: parents[ele.id], // Assuming you have some logic to assign parents if necessary
      label: ele.label,
      comparison: ele.comparison,
      value: ele.value,
    };
  });

  // we're here in a flow from graphCy to graphFilters,
  // therefore we convert cy-edge data into CyFilter Data
  //
  filterElements.edges.map(ele => {
    if (typeof ele.id === 'undefined') {
      throw new Error('EdgeElement has no ID');
    }
    filters.edges[ele.id] = {
      id: ele.id,
      source: ele.source,
      combinator: ele.combinator ?? 'nocombinator',
      target: ele.target,
    };
  });

  console.log('the prepared filters', { filters, stack: new Error() });

  //dispatch to graphFilters
  uiDuck.dispatcher.filters(dispatch, {crud: 'c_u_', storageFilterCombo: filters});

  // dispatch to workspace
  // userDuck.dispatcher.update_workspace(dispatch, filters)
};

export const testOnly_processFilters = async (
  cy: cytoscape.Core,
  dispatch: Dispatch<any>,
  elements: {
    nodes: helper.CyFilter[]
    edges: helper.CyFilterEdge[]
  },
) => {
  await processFilters(cy, dispatch, elements);
};

const checkFilterelementsIfFilledCorrectly = (filtereles: {
  edges: cytoscape.EdgeCollection
  nodes: cytoscape.NodeCollection
}) => {
  if (!filtereles.edges.length && !filtereles.nodes.length) {
    return false;
  }

  let retval = true;
  filtereles.edges.map(edge => {
    console.log('this is the fi edge', { edge });
    if (
      edge.data('source') !== 'undefined' &&
      edge.source() &&
      edge.data('target') !== 'undefined' &&
      edge.data('target') &&
      edge.data('combinator') !== 'undefined' &&
      edge.data('combinator')
    ) {
    } else {
      retval = false;
    }
  });
  filtereles.nodes.map(node => {
    console.log('this is the fi node', { node });
    if (
      node.data('comparison') !== 'undefined' &&
      node.data('comparison') &&
      node.data('label') !== 'undefined' &&
      node.data('label') &&
      node.data('value') !== 'undefined' &&
      node.data('value')
    ) {
    } else {
      retval = false;
    }
  });

  return retval;
};


export const dragFreeFunc = (cy: cytoscape.Core, e: cytoscape.EventObject, dispatch: Dispatch) => {
  console.log('Changing Position Moving a node', e);
    if (typeof e.target !== 'undefined') {
      if (e.target.isParent()) {
        console.log('Changing Position Moving a parent', e);
      }
      if (!e.target.isParent() && e.target.isChild()) {
        console.log('Changing Position Moving a Child', e);
      }
      console.log('Dispatching Change for Element', { ele: e.target });


      const nc = cy.collection().add(e.target) 
    
      // marks the new element as changed
      console.log('Marking as changed', {aa: nc.map(ele => ele.data('id'))})
      markChanged( {dispatch, cy, collection: nc });
      
      console.log('Changing Position', e);
    }
}

/**
 * Single point for intercepting
 * @param dispatch
 * @param value
 */
const triggerMarkToSave = async (
  cy: cytoscape.Core,
  dispatch: Dispatch<any>,
  value: { [key: string]: cytoscape.ElementDataDefinition },
) => {
  
  Object.keys(value).map(eleId => value[eleId].changed = 'yes')
  console.log('Saving these elements', { value, keys: Object.keys(value) });

  let filterelements: {
    nodes: helper.CyFilter[]
    edges: helper.CyFilterEdge[]
  } | null = null;

  Object.keys(value)
    .filter(key => key !== 'length')
    .map(id => {
      console.log('Checking if Id of element already exists in Cy', { id, cyidelement: cy.$(`[id = "${id}"]`).map(ele => ele.data()) });
      // see if the current id belongs to a filter-element,
      // if yes, then we delete it afterwards because we don't want filters to end in the graphdb.
      const oldfilter = cy.$(
        '[id = "' + id + '"][type = "structure"][filter = "yes"]',
      );
      const newfilter = cy.$('[id = "' + id + '"][type = "filter"]');

      const oldele = cy.$(`#${id}`);
      console.log('The ID we are processing', { id, oed: oldele.data(), ofd: oldfilter.data(), nfd: newfilter.data() });
      if (oldfilter.length > 0 || newfilter.length > 0) {
        console.log('Found Filter', { id, eg: cy.getElementById(id).group(), ed: cy.getElementById(id).data() });

        // only in case the filteelements are null do the whole run.
        // (then the filters have not been processed yet)
        if (filterelements === null) {
          // process filters the first and only time
          filterelements = { nodes: [], edges: [] };

          // fetch all filters and remove them from saving, process them
          const allFilters = cy
            .$('[type = "structure"][filter = "yes"]')
            .union('[type = "filter"]');
          allFilters.map((filter, i) =>
            console.log( 'These are all the filters we process n ' + i + ' of ' + allFilters.length, { group: filter.group(), filter: filter.data() } ),
          );
          //add element to collection for further processing
          allFilters.map(ele => {
            console.log('iterating all filters', { ele: ele.data(), node: ele.isNode() });

            if (!filterelements) {
              throw new Error('FilterElement is null, shouldnt be possible');
            }
            if (ele.isNode()) {
              console.log('iterating all filters nodes', { ele: ele.data(), elepos: ele.position(), newfilterdata: newfilter.data(), newfilterpos: newfilter.position(), oldeledata: oldele.data(), oldelepos: oldele.position(), value });

              const xx = { ...ele.data() };

              xx.isParent = ele.isParent();

              console.log('will push fl', { xx, filterelements });

              filterelements.nodes.push(xx);
              console.log('did push', { filterelements: Object.entries(filterelements.nodes[0]) });
            } else {
              filterelements.edges.push(_.cloneDeep(ele.data()));
            }
          });
          console.log('stepping out', {});
        }
        console.log('Filter Elements are processed', { filterelements });
        console.log('deleting', { id, value: value[id] });
        // remove this value, as the remaining values will be processed as `non-filter` elements
        delete value[id];
        console.log('deleted', {});
      } else {
        console.log( 'No Filter found - OED, ie the element to save, is no filter', { oed: oldele.data(), id, cyeleid: cy.getElementById(id), filter: '[id = "' + id + '"][type = "structure"][filter = "yes"] or [id = "' + id + '"][type = "filter"]' } );
      }
    });
  console.log('Filter Elements should be processed', { filterelements });

  ////
  /// Start Processing the identified Filter Elements
  //
  if (filterelements) {
    Object.entries(
      filterelements as {
        nodes: helper.CyFilter[]
        edges: helper.CyFilterEdge[]
      },
    ).map(([k, v]) =>
      v.map(val => console.log('filterelements this here is', { k, val })),
    );

    // process the filters further
    await processFilters(cy, dispatch, filterelements);
  } else {
    // no filter elements identified
  }

  // in case there are still values which need to be saved, since filters are cleaned out
  if (Object.keys(value).length) {
    console.log('Still here to mark as saved', { value, isArray: _.isArray(value) });
    backendDuck.dispatcher.markToSave(dispatch, value);
  } else {
    // console.log('Not Still here to mark as saved', { value })
  }
};

/**
 * changes a related element from the properties
 *
 * @param dispatch
 * @param element
 * @param {cy: Cytoscape}
 */
export const changeRelated = (
  dispatch: Dispatch,
  element: {
    selectedId: string
    [key: string]: any
  },
  { cy, relation }: { cy: cytoscape.Core | null; relation: string } = {
    cy: null,
    relation: '',
  },
  options = {},
) => {
  if (!cy) {
    throw new Error('no cy delivered');
  }
  const ele = cy.$(`[id="${element.id}"]`);
  const edges = ele.connectedEdges(`[label="${relation}"]`);

  // delete possibly existing edges with the same name
  cy.remove(edges);

  const newedge = {
    // edge e1
    data: {
      ...options,
      id: uuidv4(),
      // inferred as an edge because `source` and `target` are specified:
      source: ele.id(), // the source node id (edge comes from this node)
      target: element.selectedId, // the target node id (edge goes to this node)
      // (`source` and `target` can be effectively changed by `eles.move()`)
      changed: 'yes',
      label: relation,
    },
  };
  console.log('reached changeRelated', { ele, newedge, edges, element, relation });
  const thenew = cy.add(newedge);
  triggerMarkToSave(cy, dispatch, { [thenew.data('id')]: thenew.data('label') });
  // element.data('changed', 'yes');
};

/**
 * only manipulates cy, doesn't dispatch
 * update graphFilter elements and their properties to the cy graph
 *
 * might be called from
 * - backend load process
 * - frontend edit process
 *
 * @param dispatch
 * @param graphFilters
 * @param {cy: Cytoscape}
 */
export const ensureFiltersInCyReflectGraphfilters = (
  graphFilters: helper.StorageFilterCombo = { nodes: {}, edges: {} },
  { cy }: { cy: cytoscape.Core | null } = { cy: null },
  options: { position?: { x: number; y: number }; [key: string]: any } = {},
) => {
  if (!cy) {
    throw new Error('no Cy Delivered');
  }
  // Start with filter-blank cy
  const oldFilters = cy.$('[type = "filter"]');
  oldFilters.remove();

  console.log('Start to update Filters in Cy', {oldFilters, graphFilters })
  // iterate over each graphFilter-Node from Redux Store
  const newnodes = Object.entries(graphFilters?.nodes).map(([k, v], i) => {
    if (!v.id) {
      throw new Error('no id given');
    }
    // check if the filter id did exists in cy graph before
    const oldFilter = oldFilters.getElementById(v.id) ?? null;

    ////
    /// Get Position
    //  if no position exists, distribute them a little bit (ie * i)

    let pos: { x: number; y: number } = v.position ?? { x: 1, y: 1 };

    if (v.position && !oldFilter.position() && !options.position) {
      // set position
      console.log('this is the old filter', { optionpos: options.position, vp: v.position, oldFilter, od: oldFilter.data(), op: oldFilter.position() });
      oldFilter.position(v.position);
      options.position = v.position;
    } else {
      // ensuring position is set based on the provided position
      // instead of some calculated one.
      if (v.position && options.position) {
        pos = v.position;
      }

      if (!v.position) {
        pos =
          oldFilter && oldFilter.position()
            ? oldFilter.position()
            : (options?.position ?? { x: 10 * i, y: 10 * i });
      }
    }

    console.trace('coming through here filters pos', { v, graphFilters, op: oldFilter.position(), oldFilter, options });
    console.log('coming through here filters pos', { v, graphFilters, op: oldFilter.position(), oldFilter, options });

    ////
    /// prepare new Filter / this can update a potentially existing filter in cy or add it new
    //

    if (typeof v.position !== 'undefined') {
      pos.x = _.toNumber(v.position.x)
      pos.y = _.toNumber(v.position.y)
      // delete v.position
    } else if (
      typeof oldFilter !== 'undefined' &&
      typeof oldFilter?.position() !== 'undefined'
    ) {
      pos.x = oldFilter.position().x
      pos.y = oldFilter.position().y
    }
    
    // @todo, this is just a workaround - check why NaN happens
    pos.x = _.isNaN(pos.x) ? 0 : pos.x
    pos.y = _.isNaN(pos.y) ? 0 : pos.y

    console.log('what is the pos', { pos });

    // position can sit in the options - here we kick it out.
    const { position, ...optrest } = { ...options };
    // ## Position
    //////

    // ## Node Details
    const newNode: cytoscape.NodeDefinition = {
      //
      group: 'nodes',
      data: {
        ...optrest,
        id: typeof v.id !== 'undefined' ? v.id : uuidv4(),
        type: 'filter',
        changed: 'yes',
        label: v?.label,
        comparison: v?.comparison,
        value: v?.value,
      },
      position: pos,
    };

    // ## add node to collector
    console.log('The new node prepared', { newNode: newNode });
    return newNode;
  });

  console.log('The new nodes are prepared', { graphFilters, newnodes });

  // iterate over each graphFilter-Node from Redux Store
  const newEdges = Object.entries(graphFilters?.edges).map(([k, v], i) => {
    // check if the filter id did exists in cy graph before
    const oldFilter = oldFilters.getElementById(v.id) ?? null;

    console.log('The edge being processed here', { v, oldFilter });
    ////
    /// prepare new Filter / this can update a potentially existing filter in cy or add it new
    //

    // ## Edge Details
    const newEdge: cytoscape.EdgeDefinition = {
      //
      group: 'edges',
      data: {
        ...options,
        id: typeof v.id !== 'undefined' ? v.id : uuidv4(),
        type: 'filter',
        changed: 'yes',
        source: v?.source,
        target: v?.target,
        combinator: v?.combinator,
      },
    };

    // ## add node to collector
    console.log('The new edge prepared', { newEdge });
    return newEdge;
  });

  const insert: ElementsDefinition = { nodes: newnodes, edges: newEdges };

  try {
    cy.add(insert);
  } catch (error) {
    console.log('Error during Insert', { insert, error });
    throw error;
  }

  console.log('These are the new Filters built and should have been added', { visi: cy .$(':visible') .filter('[type = "filter"]') .map(filter => [filter.group(), ...Object.entries(filter.data())]), insert, newnodes, newEdges: Object.values(newEdges) });

  // now we actually filter

  const currentFilters = helper.filterCy(cy, graphFilters);
};

export const assessSomeWrongGraphFilter = (
  graphFilters: helper.StorageFilterCombo
): Array<helper.StorageFilter> => {
  let someWrong: Array<helper.StorageFilter> = [];

  if (graphFilters && typeof graphFilters.nodes !== 'undefined' && graphFilters.nodes) {
    console.log('this is graphfilters', { gf: graphFilters.nodes });
    someWrong = Object.values(graphFilters.nodes).filter((node) => {
      console.log('Checking if label is set and value is set', {
        la: node.label,
        va: node.value,
        tf: node.label == null || node.value == null || node.label == "" || node.value == ""
      });
      return node.label == null || node.value == null || node.label == "" || node.value == "";
    });

    if (someWrong.length > 0) {
      console.log('the graph filters are in effect after not allok', {
        someWrong, 
        graphFilters,
        gg: Object.entries(graphFilters.nodes),
        o: Object.entries(graphFilters.nodes)
            .filter(([key, node]) => 
              {
                console.log('the graph filters are in effect after not the a and b',{
                  a: node.label != null,
                  b: node.value != null
                })
                return node.label == null || node.value == null 
              })
            .map((x) => console.log('the graph filters are in effect after not mapped the key and node',{x}) )
      })    }
  }
  return someWrong;
}

export const createDuplicateCollection = (cy: cytoscape.Core, eles: cytoscape.CollectionReturnValue, factor: number, startId: string): cytoscape.ElementDefinition[] => {

  const processed : cytoscape.ElementDefinition[] = []

  const checkId = (id: string, newIds: {[key:string]: string} ): {newId: string, newIds: { [key: string]: string; }} => {
    let newId: string
    if ( newIds[id] ){
      // id already exists
      newId = newIds[id]
    } else {
      // needs to be processed
      newId = uuidv4()
      newIds[id] = newId
    }
    return {newId, newIds}
  }

  let newIds: {[key:string]: string} = {[startId]: startId} 
  console.log('ensuring that startid is startid', {startId, newIds})
  let newId: string

  eles.map(ele => {
    ({ newId, newIds } = checkId(ele.id(), newIds));
    console.log('ensuring that startid is startid2', {startId, newIds})

    const data = {...ele.data()}
    
    // assign the new id
    data.id = newIds[ele.id()]
    
    if ( ele.isEdge() ){
      let sourceId: string
      ({ newId: sourceId, newIds } = checkId(data.source, newIds));
      console.log('ensuring that startid is startid3', {startId, newIds})
      // update source and target
      data.source = sourceId

      let targetId: string
      ({ newId: targetId, newIds } = checkId(data.target, newIds));
      console.log('ensuring that startid is startid4', {startId, newIds})
      // update source and target
      data.target = targetId
    }


    const ele2: cytoscape.ElementDefinition = { data }
    
    if (ele.isNode()){
      ele2.position = {x: ele.position().x * factor, y: ele.position().y * factor}
    }
    console.log('we have a new id for it', {newId, newIds, elid: ele.id(), node:ele.isNode(), ed: ele.data(), e2d: ele2.data})
    console.log('ensuring that startid is startid5', {startId, newIds})
    processed.push(ele2)
  })
  return processed
}

/**
 * previously not as changed marked elements get marked now
 * @param dispatch
 * @param element
 * @param param2
 */
export const markChanged = (
  {
    dispatch,
    cy,
    collection,
  }: { 
    dispatch: Dispatch<any>,
    cy: cytoscape.Core
    collection: cytoscape.Collection
  },
) => {
  console.log('1 reached markChanged', { cy, coll: collection.map(ele => ele.data()), stack: new Error() });

  const other: { [key: string]: cytoscape.ElementDataDefinition } = 
  collection
  .reduce((acc, element) => {
    const id = element.data('id') ;
    acc[id] = element.data();
    return acc;
  }, {} as { [key: string]: cytoscape.ElementDataDefinition });
  // .map(element => ({[element.data('id')]: element})

  console.log('Created Other to trigger Mark to Save now', {other})
  triggerMarkToSave(cy, dispatch, other);
  
};

/**
 * previously not as changed marked elements get marked now
 * @param dispatch
 * @param element
 * @param param2
 */
export const markChangedNonCy = (
  dispatch: Dispatch,
  element: { [key: string]: cytoscape.ElementDataDefinition },
  cy: cytoscape.Core,
) => {
  triggerMarkToSave(cy, dispatch, element);
};

export const warnAboutSomething = async (
  state: uiDuck.InitialState,
  dispatch: Dispatch,
  list: string[],
  text: string,
  ask = true,
) => {
  console.trace('reached here warn');
  if (ask) {
    // trigger customer confirmation and wait for anwer
    uiDuck.dispatcher.update_warning(dispatch, {
      show: true,
      content: {
        text,
        list,
      },
    });
    await state.promise?.promise;
  }
  // answer arrived and confirmed to proceed
};

const handleFilterDeletes = async (
  state: uiDuck.InitialState,
  dispatch: Dispatch,
  cy: cytoscape.Core,
  collection: cytoscape.CollectionReturnValue,
  ask = false,
  meta : uiDuck.InitialState['meta'] = {original: []}
) => {
  let coll = cy.collection()
  collection.map(element => {
    cy.edges(`[source = "${element.id()}"],[target = "${element.id()}"]`).map(edge => {
      console.log('the coll has edges', {edge})        
      coll = coll.add(edge)
    })
  })

  //
  
  collection = collection.union(coll)

  // remove all nodes and their connected edges from cy
  collection.map(element => cy.remove(`[id = "${element.id()}"]`))

  collection.map(element => console.log('at least here gone', cy.getElementById(element.id())))

  console.log('the coll has edges here too', {collectiondata: collection.map(ele => ele.data())})  

  const nodes = collection.nodes().map(node => node.data())
  const edges = collection.edges().map(edge => edge.data())

  console.log('deleting these nodes and edges ',{nodes, edges})
  await processFilters(cy, dispatch, {nodes, edges}, true);

  // in case the meta has that particular element set, update the meta
  if (meta.original.some(om => collection.some( collectionEle => collectionEle.data('id') === om.id ) )){
    uiDuck.dispatcher.showMeta(dispatch,[])

  }
  // markChanged({dispatch, cy, collection });
};

export const handleDeleteElements = async (
  state: uiDuck.InitialState,
  dispatch: Dispatch,
  cy: cytoscape.Core,
  element: cytoscape.Singular,
  ask = true,
) => {
  console.log('deletex Promise resolved:');

  let standardElementsToDelete = cy.collection();
  let filterElementsToDelete = cy.collection();

  // used for user confirmation question
  const names: { edges: number; nodes: string[] } = { edges: 0, nodes: [] };


  // add the element on which the delete has been fired on
  // to a potentially existing selection and ask if all shall be deleted
  const selected = cy.$(':selected').merge(element);
  selected.map(ele => {
    console.log('is af ilter', {edf: ele.data(), edt: ele.data('type')})
    if (ele.data('type') === 'filter' ) {
      // if element is filter
      console.log('hier is a filter', { ed: ele.data() });
      filterElementsToDelete = filterElementsToDelete.add(ele)
      console.log('hier is a filter coll', { filterElementsToDelete, ed: ele.data() });
      return; // Skips the current iteration
    }

    // add to collection
    standardElementsToDelete = standardElementsToDelete.add(ele);
    console.log('Coming by hier', {all: standardElementsToDelete, ed: ele.data()})

    //remember node name to ask user specifically
    if (ele.isNode()) {
      names.nodes.push(ele.data('label'));
    }
    
  });
  
  ///
  // selected must not be used after this point
  //
  console.log('proceeding to delete filter', { fis: filterElementsToDelete.map(fi => fi.data())})

  if (filterElementsToDelete.length){
    console.log('proceeding to delete filterxx ', { fis: filterElementsToDelete.map(fi => fi.data())})

    await handleFilterDeletes(state, dispatch, cy, filterElementsToDelete);
    console.log('filter still visible?', { fis: filterElementsToDelete.map(fi => ({id: fi.id(), vis: cy.getElementById(fi.id())}))})


  }

  // count amount of to be deleted edges
  names.edges = standardElementsToDelete.edges().length;

  console.log('collection to deletex', { collection: standardElementsToDelete.map(ele => ele.data()), names });

  // ask if it's configured and if there are elements to delete left (could have been triggered just by filterdelete)
  if ((names.nodes.length > 0 || names.edges > 0)) {
    try {
      if (ask){
        await warnAboutSomething(
          state,
          dispatch,
          [
            ...(names.nodes.length > 0 ? names.nodes : ['']),
            names.edges > 0 ? `edges: ${names.edges}` : '',
          ],
          'Delete the following elements:',
          ask,
        );
        // answer arrived and confirmed to proceed
      }

      console.log('proceeding to delete', {coll: standardElementsToDelete})
      // removes selected, ie filter as well as elements
      // @todo debug cy when there's time
      // cy.remove(selected);
      // cy.resize()
      // console.log('All selected gone?', {here: selected.map(el => ({id: el.id(), visible: cy.getElementById(el.id()).visible()}))})
      
      standardElementsToDelete.map(el => cy.remove(`[ id = "${el.id()}"]`))
      
      // console.log('All selected gone now?', {here: selected.map(el => ({id: el.id(), visible: cy.getElementById(el.id()).visible()}))})
      

      console.log('done with delete a', {stnill: cy.getElementById(element.id())})

      // collection contains only delete elements, as filter have been handled before
      backendDuck.dispatcher.deleteThese(dispatch, standardElementsToDelete);
      
      console.log('done with deletexx', {selected : selected.getElementById(element.id()), all: standardElementsToDelete, elestillhere: cy.getElementById(element.id()).visible()})

      uiDuck.dispatcher.d_uiListElements(
        dispatch,
        Object.values(standardElementsToDelete.nodes).map(val => val.data),
      );

      console.log('done with delete')
      console.log('done with deletex', {elestillhere: cy.$(element.id()), coll: standardElementsToDelete})

    } catch (error) {
      console.error('deletex Promise rejected:', error);
    } finally {
      //          uiDuck.dispatcher.update_warning(dispatch, {promise:} )
      console.log( 'deletex Promise operation completed, regardless of resolution or rejection.', state.customerWarning, state.promise );
    }
  }
};

export const createElement = ({
  setactive = true,
  newJson,
  dispatch,
  cy,
  type,
}: {
  setactive?: boolean
  newJson: ElementDefinition
  dispatch: Dispatch
  cy: cytoscape.Core
  type: string
}): string => {

  if (typeof cy === 'undefined'){throw new Error('Cy is undefined')}
  // const newJsonObject = JSON.parse(newJson)
  const newJsonObject = newJson;
  if (type === 'node') {
    uiDuck.dispatcher.crud_uiListElements(dispatch, [newJsonObject]);
  }

  console.log('the new json to add', {newJsonObject, cy})
  const newEle = cy.add(newJsonObject);
  console.log('The new Cy', { all: cy.elements().map(ele => ele.data())})
  
  const nc = cy.collection().add(newEle)

  console.log('These are the eles collected', {ned: {data: newEle.data(),type: newEle.isNode }, all: nc.map(ele => ele.data())})
  // marks the new element as changed
  markChanged( {dispatch, cy, collection: nc });

  if (newEle.isNode()) {
    // provides the data in array shape as expected upstream
    const data2: cytoscape.NodeDefinition[] = [];
    data2.push(newEle.data());

    // sets the node in the edit view
    if (setactive) {
      helper.selected2Meta(dispatch, data2);
    }

    // uiDuck.dispatcher.showMeta(dispatch, cy.getElementById(newId).json());
    // add changes to left panel
    console.log('Updating the left after Created new Element from Event', { data2 });

    console.log('the data for update the left x', { data2 });

    graphhandling.update_theleft(cy, dispatch, { nodes: data2, edges: [] });
  }

  console.log('Expect to see the new node in theleft and in meta', 'tl', newEle.id());
  return newEle.id()
};

export const addNewEdgeToElement = ({
  isfilter = false,
  relationLabel,
  sourceId,
  graphFilters,
  targetId,
  dispatch,
  cy,
}: {
  isfilter?: boolean
  relationLabel: string
  sourceId: string
  graphFilters: helper.CyFilterCombo
  targetId: string
  dispatch: Dispatch
  cy: cytoscape.Core
}) => {
  console.log('Start: ehcancel created new edge', { isfilter, relationLabel, sourceId, graphFilters, targetId, dispatch, cy });

  // extracts the filter labels and values so that they can be added to the new edge
  const filters = Object.entries(graphFilters.nodes).reduce(
    (acc, [k, v]) => ({ ...acc, [v.label ?? 'nolabel']: v.value }),
    {},
  );

  const newedge: cytoscape.ElementDefinition = {
    data: {
      //if it is a filter, then we don't need to add the filtered properties from graphFilter (otherwise we need them)
      ...(isfilter ? { type: 'filter', combinator: 'AND' } : { ...filters }),
      id: uuidv4(),
      source: sourceId, // the source node id (edge comes from this node)
      target: targetId, // the target node id (edge goes to this node)
      // (`source` and `target` can be effectively changed by `eles.move()`)
      changed: 'yes',
      label: relationLabel,
    },
  };

  if (isfilter) {
    newedge.data.combinator = 'AND';
  }

  if (!newedge.data.id) {
    throw new Error('no id');
  }

  // console.log('reached changeRelated', {ele,newedge, edges, element, relation})
  cy.add(newedge);
  console.log('ehcancel created new edge', { newedge });
  triggerMarkToSave(cy, dispatch, {
    [newedge.data.id as string]: newedge.data,
  });
};

export const createElementAndEdge = ({
  isfilter,
  relationLabel,
  sourceId,
  graphFilters,
  position,
  dispatch,
  cy,
}: {
  isfilter: boolean
  relationLabel: string
  sourceId: string
  graphFilters: helper.CyFilterCombo
  position: cytoscape.Position
  dispatch: Dispatch
  cy: cytoscape.Core
}) => {
  console.log('Creating These Elements', { relationLabel, sourceId, graphFilters, position });

  // prepares new node from given graphFilter Set and the rendered Position of the edge location
  const newNode = prepareNewEleData({
    isfilter,
    elementType: 'nodes',
    graphFilters,
    renderedPosition: position,
  });
  console.log('New Node Data prepared', { newNode });

  // creates the prepared node
  createElement({
    setactive: false,
    newJson: newNode,
    dispatch,
    cy,
    type: 'node',
  });
  if (!newNode.data.id) {
    throw new Error('no id');
  }

  // adds new edge which connects the new node to the element.
  addNewEdgeToElement({
    isfilter,
    relationLabel,
    sourceId,
    graphFilters,
    targetId: newNode.data.id,
    dispatch,
    cy,
  });

  // option to disable showing the edge
  if (process.env.CONTROL_CREATENEWNODEANDEDGE_SHOW == null) {
    showElement(dispatch, {
      id: newNode.data.id,
      cy,
    });
  }
  console.log('Reached here now', {graphFilters, cy: cy.$('[type = "filter"]').map(ele => ele.data())})
};

/**
 * adds all currently set filters as metadata for new element
 * @param param0
 * @returns
 */
export const prepareNewEleData = ({
  isfilter = false,
  elementType,
  graphFilters,
  edgeData = { source: '', target: '' },
  renderedPosition = { x: 0, y: 0 },
}: {
  isfilter?: boolean
  elementType: 'nodes' | 'edges'
  graphFilters: helper.StorageFilterCombo
  edgeData?: { source: string; target: string }
  renderedPosition?: cytoscape.Position
}): cytoscape.ElementDefinition => {
  const newId = uuidv4();

  const new_data: { [key: string]: string | string[] } = {
    id: newId,
  };

  // adding all currently set filters as metadata for the new element,
  // as we assume we create elements for the same context we're in rn
  console.log('Graph Filters being worked on here', {graphFilters});
  Object.values(graphFilters.nodes).map(filternode => {
    if (filternode.comparison === '=') {
      if (!filternode.label) {
        throw new Error('Filternode should have a label');
      }
      if (typeof filternode.label !== 'string') {
        throw new Error('Filternode Label is StringArray');
      }
      if (typeof filternode.value === 'undefined') {
        throw new Error('Filternode Value is undefined');
      }
      const filterlabelstring: string = filternode.label;
      new_data[filterlabelstring] = filternode.value;
    }
  });

  console.log('New Data prepared', { new_data });

  if (elementType === 'nodes') {
    const conditionalData = isfilter
      ? { type: 'filter' }
      : { type: 'todo', ...new_data };

    const tmp: NodeDefinition = {
      group: _.cloneDeep(elementType),
      data: {
        label: 'new node',
        ...conditionalData,
      },
      position: renderedPosition,
      selected: true,
    };
    return tmp;
  }

  if (elementType === 'edges') {
    const newele: EdgeDefinition = {
      group: _.cloneDeep(elementType),
      data: {
        label: 'depends on',
        ...edgeData,
        // if it is a filter, then we don't need to add the filtered properties from graphFilter (otherwise we need them)
        ...(isfilter ? { type: 'filter' } : { ...new_data }),
      },
      selected: true,
    };
    return newele;
  }

  // TypeScript should never reach this point due to the type assertion
  throw new Error('Invalid elementType');
};

export const markLeafs = (
  { target, ep }: { target: any; ep: any },
  {
    dispatch,
    cy,
    state: { graphFilters },
  }: {
    dispatch: Dispatch
    cy: cytoscape.Core
    state: { graphFilters: helper.CyFilterCombo }
  },
) => {
  const visible = cy.$(':visible');
  visible.style('display', 'none');
  const leaves = visible.leaves();
  leaves.style('display', null);
};



export const loadHelper = (dispatch: Dispatch) => {
  console.log('Loading Tool Triggered')

  // load all elements from db
  backendDuck.dispatcher.loadingElements(dispatch)

  // loads workspace infos like filters, security
  userDuck.dispatcher.getWorkspace(dispatch, {})

}
export const saveHelper = (cy: cytoscape.Core, dispatch: Dispatch, workspace: {filter: helper.CyFilterCombo[]}, graphCurrentPage: number ) => {
  

  // Persisting Workspace
  // takes workspace, converts it to a graph with non-complex objects and sends it to neo
  persistWorkspace(dispatch, cy, workspace, graphCurrentPage)
  
  var nodes: cytoscape.NodeSingular[] = []
  cy.nodes('[type != "structure"][changed = "yes"]').filter('[type != "filter"]').map(node => nodes.push(node as NodeSingular))
  
  var edges: cytoscape.EdgeSingular[] = []
  cy.edges('[type != "structure"][changed = "yes"]').filter('[type != "filter"]').map(edge => edges.push(edge as EdgeSingular))

  console.log('saving changed nodes',{nodes, edges, cx: cy.elements('[type != "structure"][changed = "yes"]')})
  
  // check if mandatory are filled
 
  const mandatoryFieldsAreAllFilled = neo4jhelper.mandatoryFilled({nodes, edges}) 
  const allMandatorySet = neo4jhelper.checkAndHandleMandatoryCheckFailed(mandatoryFieldsAreAllFilled,cy, (data: any) => { 
    helper.selected2Meta(dispatch, [data])
  })
  
  console.log('onSave: The changed Nodes and Edges, all Mandatory set?', {nodes, edges, allMandatorySet})
  const {nodeJson, edgeJson} = neo4jhelper.removeChangedFromElementAndConvertToJson(nodes, edges)

  backendDuck.dispatcher.saveFunction(dispatch, {nodeJson, edgeJson})

  // loads workspace infos like filters, security
  userDuck.dispatcher.getWorkspace(dispatch, {})
  
}


// // to be called in cy-events
// // inserts the chosen event target as input for edit-view
// //
// export const showElement = (
//   dispatch,
//   {
//     target = null, cy, id,
//   }: { cy: any; target?: any | null , id: string},
// ) => {
//   // console.trace('where from')

//   // console.log('the target is', target)

//   let data = [];
//   if (target == 'core') {
//     // console.log('the showeles', {cy})

//     const selected = cy.elements(':selected');
//     //console.log('the  showeles', { entry: selected });

//     selected.map((element) => {
//       console.log('the key, the  showeles', {
//         element,
//         element,
//         rata: element.data(),
//       });
//       data.push(element.data());
//     });
//     // Object.keys(selected)
//     //   .filter(key => !isNaN(key) )
//     //   .map(key => {
//     //     data[key] = selected[key].json().data
//     // })
//   } else {
//     console.log('The Menu Items which contain the one that shall be shown', {id, eledata: cy.getElementById(id).data()})
//     // data.push(cy.getElementById(id).json().data)
//     data = [cy.getElementById(id).data()];
//   }

//   helper.selected2Meta(dispatch, data);

//   console.log('Done showing Elements - these are in meta now', {data})
//   // helper.toggleMaxview(menuItems, event.id(), dispatch);
// };

export const createEleFromEvent = (
  { target, ep }: { target?: cytoscape.NodeSingular; ep?: {position?: {x:number, y:number}} },
  {
    dispatch,
    cy,
    state: { graphFilters },
  }: {
    dispatch: Dispatch
    cy: cytoscape.Core
    state: { graphFilters: helper.CyFilterCombo }
  },
): cytoscape.ElementDataDefinition['id'] => {
  const renderedPosition = ep?.position ?? {x:1, y:10};

  // handleDeleteElements(edge);]
  console.log('Creating new Element from Event', { target, ep, graphFilters });
  console.log( 'the filters now when Creating new Element from Event', graphFilters );

  // const name = prompt('Name des Nodes', 'hier');
  const elementType = 'nodes';

  const isfilter = false;
  let newNode = prepareNewEleData({
    elementType,
    graphFilters,
    renderedPosition,
  });
  // add parent relationship
  if (target && typeof target.data('id') !== 'undefined') {
    newNode = {
      ...newNode,
      data: { ...newNode.data, parent: target.data('id') },
    };
  }

  console.log('this is the new node', { newNode });

  // adds `changed yes` to ensure that all new elements will be saved
  createElement({ newJson: {...newNode, data: {...newNode.data, changed: 'yes'} }, dispatch, cy, type: 'node' });
  return newNode.data.id;
};

// to be called in cy-events
// inserts the chosen event target as input for edit-view
//
export const showElement = (
  dispatch: Dispatch,
  {
    target,
    cy,
    id,
    just,
  }: {
    cy: cytoscape.Core
    just?: ElementDefinition['data']['id']
    target?: string
    id: string
  },
) => {
  // console.trace('where from')

  console.log('the target is', target);


  let data: cytoscape.ElementDataDefinition[] = [];
  if (target == 'core') {
    let selected = cy.elements(':selected');
    // console.log('Selected is what in helper',{selected})
    // selected.map(ele => console.log('the showeles', {ed: ele.data()}))

    if (just) {
      selected = selected.filter(just);
    }

    //console.log('the  showeles', { entry: selected });

    selected.map(element => {
      console.log('the key, the showeles', { element, rata: element.data() });
      data.push(element.data() as cytoscape.ElementDataDefinition);
    });

    const data2 = buildCommonValues(data)
    console.log('Data 2 is what', {data2})
    // Object.keys(selected)
    //   .filter(key => !isNaN(key) )
    //   .map(key => {
    //     data[key] = selected[key].json().data
    // })
  } else {
    console.log('The Menu Items which contain the one that shall be shown', { id, eledata: cy.getElementById(id).data() });
    // data.push(cy.getElementById(id).json().data)
    const foo : cytoscape.ElementDataDefinition = cy.getElementById(id).data() 
    data = [foo];
  }

  helper.selected2Meta(dispatch, data);

  console.log('Done showing Elements - these are in meta now', { data });
  // helper.toggleMaxview(menuItems, event.id(), dispatch);
};

// to be called in cy-events
// inserts the chosen event target as input for edit-view
//
export const showPath = async (
  dispatch: Dispatch,
  {
    ep,
    target = null,
    cy,
    id,
    hide = true,
    resetVisibility = true,
    filterfn = (x: any) => true, // allows all entries
    mycase = 'predecessors',
  }: {
    ep: any
    target?: any | null
    cy: any
    id: string | string[]
    hide: boolean | null
    resetVisibility: boolean | null
    mycase: string | null
    filterfn?: Function
  },
) => {
  // console.trace('where from')
  // console.log('the target is', target)
  let visible;
  // let cy = event.cy()
  if (target !== 'core') {
    console.log('Showing Path now', { idtype: typeof id, id, target });

    // let data = [];
    // // console.log('The Menu Items which contain the one that shall be shown', {event,id: event.id(), cy: event.cy()})
    // // data.push(cy.getElementById(id).json().data)
    if (typeof id === 'string') {
      id = [id];
    }
    const idarr = id;
    // // prepares an array of data elements from the neighboring elements
    // data =
    //   idarr.reduce((acc,id) => {Object.entries(cy.getElementById(id).closedNeighbourhood()).map(([k,v]) => {
    //       try {
    //         if (typeof v.data === 'function'){

    //           console.log('success this si the one neigbhor',{nei: v.data()})
    //           return v.data()
    //         }
    //       } catch (error) {
    //         console.log('failed this si the one neigbhor',{v, error})
    //       }
    //     },[])
    //   }).filter(item => typeof item !== 'undefined')
    const visible = cy.$(':visible');

    if (hide) {
      // hide all currently shown elements from graph
      visible.style('display', 'none');
    }

    // set visible the desired nodes and edges
    idarr.map(id => {
      switch (mycase) {
        case 'feature':
          cy.elements(`node#${id},[type="feature"]`).style('display', null);
          // cy.getElementsById(id).closedNeighbourhood().style('display',null)

          break;

        case 'neighbor':
          cy.getElementById(id).closedNeighbourhood().style('display', null);
          break;

        case 'components':
          cy.getElementById(id).component().style('display', null);
          // cy.$().componentsOf(cy.getElementById(id)).map(coll => coll.style('display',null))
          break;

        case 'successors':
          cy.getElementById(id).style('display', null);
          cy.getElementById(id).successors().style('display', null);
          // graphhandling
          break;

        case 'predecessors':
          cy.getElementById(id).style('display', null);
          cy.getElementById(id)
            .predecessors()
            .filter(filterfn)
            .style('display', null);
          console.log('We show predecessors now', { predecessors: cy.getElementById(id).predecessors().filter(filterfn) });
          break;

        default:
          break;
      }
    });

    if (resetVisibility) {
      // reset visibility
      await helper.wait(5000);
      visible.style('display', null);
    }
  }
};

// adds the goal to the 'hasGoal' collection
const set_goal = (
  node: NodeSingular,
  newGoal: NodeSingular['id'],
  recursive = true,
) => {
  const oldGoal = node.data('hasGoal');

  if (oldGoal) {
    // old goal already is a Set, we can add and it stays unique

    const unique = new Set([...oldGoal, newGoal]);
    const setIter = unique.values();
    const arr = Array.from(setIter);

    node.data('hasGoal', arr);
  } else {
    // old goal is false / null - set a first goal
    // PUPEK!!!!!
    node.data('hasGoal', [newGoal]);
  }

  if (recursive && node.isParent()) {
    // in case this is a compound
    // set recursively all children to the same goal
    node.children().map(childNode => {
      // recursively set all children to the outermost goal
      // Limitation: If Parent doesnt have a goal connection it stays goal-less
      // danger of endlessloop if we handle parents here as well
      set_goal(childNode, newGoal, recursive);
    });
  }

  if (node.isChild()) {
    // in case this is a child in a compound
    // set the straight line of parents to the same goal (not the uncles though)
    // nonrecursively (ie do not trigger the child-processing in the next step
    node.parents().map(parentsNode => {
      // recursively set all children to the outermost goal
      // Limitation: If Parent doesnt have a goal connection it stays goal-less
      // danger of endlessloop if we handle parents here as well
      console.log( 'aset', typeof parentsNode.data('hasGoal'), parentsNode.data('hasGoal') );
      if (
        !parentsNode.data('hasGoal') ||
        !parentsNode
          .data('hasGoal')
          .find((goal: cytoscape.NodeSingular['id']) => newGoal === goal)
      ) {
        // only proceed if parent node doesn't have this goal
        set_goal(parentsNode, newGoal, false);
      }
    });
  }
};

// search if connected nodes contain goals
export function goal_dfs(cy: cytoscape.Core, root_node: NodeSingular) {
  const dfs = cy.elements().dfs({
    roots: root_node,
    visit(
      current_node,
      edge_connecting_previous_current,
      previous_node,
      ith_visited_node,
      depth,
    ) {
      // finding desired node
      if (current_node.data('type') == 'goal') {
        return true;
      }

      if (
        typeof current_node.data('hasGoal') === 'undefined' ||
        current_node.data('hasGoal') == false
      ) {
        // if 'hasGoal' is false or hasn't been visited.
        current_node.data('hasGoal', false);
      } else {
        // it is possible to return early here
        // then the set nodes need to be considered as goals for the current dfs
        // this implicitly assumes no changes in the downstream graph
        // return true;
      }
    },
    directed: true,
  });

  if (dfs.found.length == 0) {
    // console.log( 'dfs No Goal found ' , dfs );
  } else {
    dfs.path.map(goal_path_ele => {
      if (goal_path_ele.isNode()) {
        // skip edges
        set_goal(goal_path_ele, dfs.found.data('id'));
        //	console.log( 'dfs Goal path node found ' , goal_path_ele.data() );
      }
    });
  }
}



// adds provided uuidElements (see validator below) and adds them in a consistent way to the tool.
// currently doesn't consider the uuidElements could be filters.
export const addToCyAndAll = (dispatch: Dispatch, cy: cytoscape.Core, uuidElements: cytoscape.ElementDefinition[], layoutStyle: string = 'circle')=> {
  const newcoll = cy.add(uuidElements);
  const layout = newcoll.layout({
    name: layoutStyle,  // Use selected layout style
    // fit: false,         // Prevent the viewport from zooming automatically
  });
  layout.run();
  
  console.log('Now mark changed', {newcoll, e: cy.elements().map(ele => ele.data())})
  markChanged({ dispatch, collection: newcoll, cy });
  console.log('Check if Cy are changed yes', {newcoll, e: cy.elements().map(ele => ele.data())})

  const newDict = helper.calculateVisibleCyDictionary(cy);
  console.log('New Dict contains what now', {newDict})

  // // dispatch new Dictionary to store
  uiDuck.dispatcher.updateDictionary(dispatch, newDict);
}


// validate a string and check if it could get imported to cytoscape
// replaces ids with uuids, as it's build for cytoscapeImporter
export const validator = (jsonInput: string): true | cytoscape.ElementDefinition[] => {


  interface Node {
    id: string;
    label?: string;
  }

  interface Edge {
    id: string;
    source: string;
    target: string;
    label?: string;
  }

  type Element = Node | Edge;
  const nodeSchema: JSONSchemaType<Node> = {
    type: "object",
    properties: {
      id: { type: "string" },
      label: { type: "string", nullable: true }
    },
    required: ["id"],
    additionalProperties: true,
    not: {
      anyOf: [
        { required: ["source"] },
        { required: ["target"] },
      ],
    }
  };

  const edgeSchema: JSONSchemaType<Edge> = {
    type: "object",
    properties: {
      id: { type: "string" },
      source: { type: "string" },
      target: { type: "string" },
      label: { type: "string", nullable: true }
    },
    required: ["id", "source", "target"],
    additionalProperties: true
  };

  const elementSchema: JSONSchemaType<Element[]> = {
    type: "array",
    items: {
      oneOf: [nodeSchema, edgeSchema]
    }
  };

  const ajv = new Ajv({ allErrors: true });
  const validate = ajv.compile(elementSchema);

  if (jsonInput.length === 0) {
    alert('No elements to import.');
    return true;
  }
  const elements: Element[] = JSON.parse(jsonInput);
  if (!validate(elements)) {
    console.error("Validation errors:", validate.errors);
    alert('Invalid input format. Please check your JSON structure.');
    throw new Error('Invalid input format. Please check your JSON structure.');
  }
  
  if (elements.length === 0) {
    alert('No elements to import.');
    return true;
  }

  const idMapping = new Map<string, string>();

  try{
    elements.forEach(element => {
      if (typeof idMapping.get(element.id) !== 'undefined') {
        throw new Error(`ID is a duplicate: ${element.id}`);
      }
      idMapping.set(element.id, uuidv4());
    });
  } catch(error) {
    alert((error as Error).message);
    throw error

  }

  const isEdge = (el: Element): el is Edge => {
    return 'source' in el && 'target' in el;
  };

  const uuidElements: ElementDefinition[] = elements.map(el => {
    if (isEdge(el)) {
      return {
        group: 'edges',
        data: {
          ...el,
          id: idMapping.get(el.id)!,
          source: idMapping.get(el.source)!,
          target: idMapping.get(el.target)!,
          imported_timestamp: Date.now(),
        },
      };
    } else {
      return {
        group: 'nodes',
        data: {
          ...el,
          id: idMapping.get(el.id)!,
          imported_timestamp: Date.now(),
        },
      };
    }
  });

  return uuidElements
}

// move selection to other node, mark
export const importer = (cy: cytoscape.Core, node: cytoscape.NodeSingular, jsonstring: string) => {

  // add the marker
  const marked = cy.elements(':selected').addClass('new_child');
  marked.map(child => {
    console.log('has been marked', child);
  });
  // hint: click on next
}


// move selection to other node, mark
export function mark_new_child(cy: cytoscape.Core) {
  // add the marker
  const marked = cy.elements(':selected').addClass('new_child');
  marked.map(child => {
    console.log('has been marked', child);
  });
  // hint: click on next
}


// move selection to other node, adopt child
export function adopt_new_child(
  cy: cytoscape.Core,
  root_node: cytoscape.NodeSingular,
) {
  console.log('START Adopt new Child', root_node);
  const relevant_nodes = new Set<cytoscape.NodeSingular>(); // unique collection
  const new_children = cy
    .elements()
    .filter(child => {
      // remove all unmarked
      if (!child.hasClass('new_child')) return false;

      if (child.isNode() && child.isOrphan()) {
        // immediately process those without parents
        relevant_nodes.add(child);
        return false;
      }

      return true;
    })
    .map(child => {
      // for preserving the current parent/child structure (ie do not flatten the selection)
      // move the first (outermost) parent to the collection only
      console.log('process child to move?', child);

      if (child.isNode()) {
        const move_parents_instead = child
          .parents()
          .filter(parent => {
            console.log( `Filtering these parents ${parent.data('label')}${parent.id()}`, parent.selected() );
            return parent.selected();
          })
          .map(selected_parents => {
            console.log('filtered parents', selected_parents);
            return cy.$(selected_parents.id());
          });
        console.log( 'are there parents to move?', move_parents_instead, 'the child', child );
        if (move_parents_instead.length > 0) {
          move_parents_instead.map(parent => relevant_nodes.add(parent));
          // relevant_nodes.add(move_parents_instead)
        } else {
          relevant_nodes.add(child);
        }
        console.log('Relevant nodes updated?', relevant_nodes);
      }
    });

  // finally add the children to the new parent
  if (relevant_nodes.size > 0) {
    relevant_nodes.forEach(child => {
      if (root_node) {
        // if parent isn't core, then move child position to parent
        child.position(root_node.position());
        child.move({ parent: root_node.id() });
      } else {
        child.move({ parent: null });
      }
    });
  } else {
    console.log('no relevant nodes');
  }
  // remove the marker
  cy.elements().removeClass('new_child');
}

// date add days start
Date.prototype.addDays = function (days: number): Date {
  this.setDate(this.getDate() + days);
  return this; // Return modified date
};

// date add days end

// Extend Date prototype for custom formatting
Date.prototype.yyyymmdd = function () {
  const mm = this.getMonth() + 1; // Months are zero-based
  const dd = this.getDate();
  return [
    (dd > 9 ? '' : '0') + dd,
    (mm > 9 ? '' : '0') + mm,
    this.getFullYear(),
  ].join(' ');
};

// Extend Date prototype to calculate difference in days
Date.prototype.diffDays = function (first: Date, second: Date): number {
  // Normalize both dates to midnight to avoid issues with daylight saving time changes
  const date1Midnight = new Date(
    first.getFullYear(),
    first.getMonth(),
    first.getDate(),
  );
  const date2Midnight = new Date(
    second.getFullYear(),
    second.getMonth(),
    second.getDate(),
  );

  // Calculate the difference in milliseconds and then convert to days
  const diffMilliseconds = date2Midnight.getTime() - date1Midnight.getTime();
  return diffMilliseconds / (1000 * 60 * 60 * 24); // Convert milliseconds to days
};
// Extend Date prototype to convert string to Date
Date.prototype.str2Date = function (strDate) {
  const mdy = strDate.split('/');
  return new Date(parseInt(mdy[2]), parseInt(mdy[0]) - 1, parseInt(mdy[1]));
};

// Persisting Workspace
// takes workspace, converts it to a graph with non-complex objects and sends it to neo
export function persistWorkspace(
  dispatch: Dispatch,
  cy: cytoscape.Core,
  workspace: userDuck.InitialState['workspace'],
  graphCurrentPage: uiDuck.InitialState['current_page'],
): void {
  console.log('raw workspace to save', { a: JSON.stringify(workspace) });
  // adding positions of filters to workspace
  const clonedws = Object.fromEntries(
    Object.entries(workspace.filter[graphCurrentPage]?.nodes ?? {}).map(
      ([k, filter]) => [
        k,
        {
          ...filter,
          position: filter.id
            ? cy.getElementById(filter.id).position()
            : { x: 1, y: 1 },
        },
      ],
    ),
  );

  const newws = {
    ...workspace,
    filter: {
      ...workspace.filter,
      [graphCurrentPage]: {
        ...workspace.filter[graphCurrentPage],
        nodes: clonedws,
      },
    },
  };
  console.log('saving workspace', newws);
  // console.log('onSave: Security to save', { workspace: parameter })
  // userDuck.dispatcher.postSecurity(dispatch,{password:'ishere', iv: 'asetasetasetaset', salt: 'asetaset', public: 'publickey', private: 'encryptedprivatekey'})

  // parameter is a string which is understood by neo4j - should be inside the postWorkspace section though.
  const parameter = neo4jhelper.prepareGraphForNeowrite(newws);

  if (parameter) {
    console.log('onSave: Workspace to save', { workspace: parameter });
    userDuck.dispatcher.postWorkspace(dispatch, parameter);
  } else {
    console.log('combined query is empty');
  }
}
export function addNewMetadata(element: cytoscape.Singular) {
  const standard: { [key: string]: any } = {
    nodes: {
      general: {
        id: {
          unit: {
            label: 'hash',
            type: 'nominal',
          },
          amount: 1,
          entries: '',
        },
        label: {
          unit: {
            label: 'string',
            type: 'nominal',
          },
          amount: 1,
          entries: '',
        },
        type: {
          unit: {
            label: 'type',
            type: 'nominal',
          },
          amount: 1,
          entries: '',
        },
        parent: {
          unit: {
            label: 'node',
            type: 'nominal',
          },
          amount: 1,
          entries: '',
        },

        owningrole: {
          unit: {
            label: 'role',
            type: 'nominal',
          },
          amount: 1,
          entries: '',
        },
        location: {
          unit: {
            label: 'locationgroup',
            type: 'nominal',
          },
          amount: 1,
          entries: '',
        },
        description: {
          unit: {
            label: 'string',
            type: 'nominal',
          },
          amount: 1,
          entries: '',
        },
        deadline: {
          unit: {
            label: 'timestamp',
            type: 'ordinal',
          },
          amount: 1,
          entries: '',
        },
        creationTimestamp: {
          unit: {
            label: 'timestamp',
            type: 'ordinal',
          },
          amount: 1,
          entries: '',
        },
        lastAutoPosition: {
          unit: {
            label: 'timestamp',
            type: 'ordinal',
          },
          amount: 1,
          entries: '',
        },
      },
      task: {
        scheduled: {
          unit: {
            label: 'timestamp',
            type: 'ordinal',
          },
          amount: 1,
          entries: '',
        },
        waitingfor: {
          unit: {
            label: 'person',
            type: 'nominal',
          },
          amount: 1,
          entries: '',
        },
        requiredtime: {
          unit: {
            label: 'timeunit',
            type: 'ordinal',
          },
          amount: 1,
          entries: '',
        },
        requiredenergy: {
          unit: {
            label: 'energy',
            type: 'ordinal',
          },
          amount: 1,
          entries: '',
        },
        requiredroles: {
          unit: {
            label: 'role',
            type: 'nominal',
          },
          amount: 'many',
          entries: '',
        },
        requiredheadcount: {
          unit: {
            label: 'futurescience',
            type: 'nominal',
          },
          amount: 1,
          entries: '',
        },
      },
      goal: {},
      benefit: {},
      problem: {},
      assumption: {},
      knowledge: {},
    },
    edges: {
      general: {
        id: {
          unit: {
            label: 'hash',
            type: 'nominal',
          },
          amount: 1,
          entries: '',
        },
        label: {
          unit: {
            label: 'string',
            type: 'nominal',
          },
          amount: 1,
          entries: '',
        },
        support: {
          unit: {
            label: 'supportlevel',
            type: 'nominal',
          },
          amount: 1,
          entries: '',
        },
        source: {
          unit: {
            label: 'node',
            type: 'nominal',
          },
          amount: 1,
          entries: '',
        },
        target: {
          unit: {
            label: 'node',
            type: 'nominal',
          },
          amount: 1,
          entries: '',
        },
        creationTimestamp: {
          unit: {
            label: 'timestamp',
            type: 'ordinal',
          },
          amount: 1,
          entries: '',
        },
        lastAutoPosition: {
          unit: {
            label: 'timestamp',
            type: 'ordinal',
          },
          amount: 1,
          entries: '',
        },
      },
    },
  };

  const elementType = element.group();
  if (elementType === 'nodes') {
    const nodesType = element.data().type;
    var cat = 'general';
    var tst = standard.nodes[cat];
    var cnt = Object.keys(tst).length;
    for (var i = 0; i < cnt; i++) {
      // iterating over the standard, collecting all matching metadata out of the element
      var md = Object.keys(tst)[i];
      var c = element.data()[md]; // retrieving for each defined key the element's value
      if (!c) {
        // prevent to overwrite stuff
        if (c != '') {
          element.data()[md] = ''; // set empty string for the general/nodespecific element
          // here it could also be an array, if the amount of answers is >1
        } // else it is an empty string
      }
    }
    var i = 0;

    var cat: string = element.data().type; // define the kind of nodes currently is set
    var tst = standard.nodes[cat]; // get the metadata collection for the nodetype, future to be specified for edges as well
    var cnt = Object.keys(tst).length;
    for (var i = 0; i < cnt; i++) {
      // iterating over the standard, collecting all matching metadata out of the element
      var md = Object.keys(tst)[i];
      var c = element.data()[md]; // value
      if (!c) {
        if (c != '') {
          element.data()[md] = ''; // set empty string for the general/nodespecific element
          // here it could also be an array, if the amount of answers is >1
        } // else it is an empty string
      }
    }
    var i = 0;
  }
  if (elementType === 'edges') {
    const edgeType = element.data().type;
    var cat = 'general';
    var tst = standard.edges[cat];
    var cnt = Object.keys(tst).length;
    for (var i = 0; i < cnt; i++) {
      var md = Object.keys(tst)[i];
      var c = element.data()[md]; // value
      if (!c) {
        if (c != '') {
          element.data()[md] = ''; // set empty string for the general/nodespecific element
        } // else it is an empty string
      }
    }
    var i = 0;
  }

  return element;
}
