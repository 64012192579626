import neo4j, {
  Driver,
  Integer,
  Node,
  Relationship,
  Session,
} from 'neo4j-driver';
import * as _ from 'lodash';
import cytoscape from 'cytoscape';


export interface Connection {
  connection: Driver
  session: Session
}

export const connect = async (): Promise<Connection | Error> => {
  console.log('Connected with Session');
  const uri = 'bolt://db:7687';
  const user = 'neo4j';
  // const pass = 'sD~XTRwxKbV2epd2t7VXpU@dYWhcsah^';
  const pass = 'newPassword';
  const db = 'neo4j';

  const connection = neo4j.driver(uri, neo4j.auth.basic(user, pass), {
    encrypted: 'ENCRYPTION_OFF',
  });

  try {
    await connection.verifyConnectivity();
    const session = connection.session({ database: db });
    return { connection, session };
  } catch (error) {
    console.log(`connectivity verification failed. ${error}`);
    return new Error('Failed to connect to the database.');
  }
};

export const close = async ({
  connection,
  session,
}: Connection): Promise<void | Error> => {
  try {
    session.close();
    connection.close();
    console.log('Connection closed successfully.');
  } catch (error) {
    console.log(`Error closing the connection: ${error}`);
    return new Error('Failed to close the connection.');
  }
};

// ensures format compatible Cy
export function prop2Cy(
  object: {
    [key: string]: any | { constructor: { name: string }; low?: number }
  },
  ok = '',
  str = '',
  splitter = 'xyxyx',
) {
  // console.log('prop2cy',object);

  const totalObject = {};
  const tree = {};
  Object.keys(object).map(ooKey => {
    let oKey = ooKey;
    if (/^_\d/.test(oKey)) {
      oKey = oKey.replace(/^_/, '');
    }

    const strArray = oKey.split(splitter);
    // If String is to be splitted
    // console.log('the okey', { oKey, ooKey });
    switch (object[ooKey].constructor.name) {
      case 'Integer':
        var value = object[ooKey].low;
        break;
      default:
        var value = object[ooKey];
    }
    // console.log('value upfront', value.constructor.name);
    if (strArray.length > 1) {
      // console.log('Split needed',oKey);

      strArray
        .reverse()
        .reduce(
          (
            r: { [key: string]: any },
            b: string,
            index: number,
            arr: string[],
          ) => {
            // strArray.reverse().reduce((r, b, index, arr) => {
            if (!r[b]) {
              // in case it doesnt exist yet
              if (index + 1 === arr.length) {
                r[b] = value;
              } else {
                r[b] = {};
              }
            }
            return r[b];
          },
          tree,
        );

      console.log('the tree now', { tree });
      /**
      const strArray = ['a', 'b', 'c', 'd'];
      const value = 10;
          Iteration 1: b = 'd', r = {} (initially tree)
                r[b] does not exist, so it creates a new property d in r and assigns an empty object ({}) to it.

            Iteration 2: b = 'c', r = {'d': {}}
                r[b] does not exist, so it creates a new property c in r and assigns an empty object ({}) to it.

            Iteration 3: b = 'b', r = {'d': {'c': {}}}
                r[b] does not exist, so it creates a new property b in r and assigns an empty object ({}) to it.

            Iteration 4: b = 'a', r = {'d': {'c': {'b': {}}}}
                r[b] does not exist, and it is the last element of the array, so it assigns the value 10 to the property a in r.

        After the iterations are complete, the resulting tree structure is:

        javascript

        {
          'd': {
            'c': {
              'b': {
                'a': 10
              }
            }
          }
        }

       */

      //			console.log('');
    } else {
      // console.log('No Split',strArray.length, oKey);
    }
    // return object;
    //
    // const coKey = ( ok !== '') ? oKey + 'xyxyx' + ok : oKey ;
    //
    // if (typeof object[oKey] === 'object'){
    //	str = str +  obj2str(object[oKey], coKey) + '';
    // }else{
    //	str = str + coKey +': ' + JSON.stringify( object[oKey] );
    //
    // }
  });
  // console.log('The Tree', JSON.stringify(tree));
  return tree;
}

// ensures format compatible for neo4j id : "abc" vs usually {"id" : "abc"}
export function obj2str(object: {}, ok = '', str = '', splitter = 'xyxyx') {
  let res = innerObj2Str(object, ok, str, splitter);

  if (/^\d/.test(res)) {
    res = `_${res}`;
  }

  return res;
}

function innerObj2Str(
  object: { [key: string]: any },
  ok = '',
  str = '',
  splitter = 'xyxyx',
) {
  // if (typeof object === 'undefined' || object === null ) return ''

  Object.keys(object).map(oKey => {
    // adds all possible strings to the outer string
    if (str !== '') {
      str += ', ';
    }

    const coKey = ok !== '' ? oKey + splitter + ok : oKey;

    if (typeof object[oKey] === 'object') {
      str = `${str + innerObj2Str(object[oKey], coKey)}`;
    } else {
      str = `${str + coKey}: ${JSON.stringify(object[oKey])}`;
    }
  });

  return str;
}

export interface NeoGraph {
  n: Record<number, Node>
  r: Record<number, Relationship>
}

/**
 * Creates a new node with the given name.
 *
 * @param {string} name - The name for the node.
 * @returns {Node} - The created node.
 */
const createNewNode = (name: string): Node => {
  const newNode: Node = {
    labels: [name],
    identity: Math.floor(Math.random() * 10000) as unknown as Integer,
    properties: {},
  };

  console.log( `Create New Node: name=${name}, newNode=${JSON.stringify(newNode)}` );
  return newNode;
};

/**
 * Prepares the data for a node relationship in a graph.
 *
 * @param {string} key - The key for the node.
 * @param {Graph} acc - The graph object.
 * @param {number|null} parentId - The ID of the parent node or null if it is the root element.
 * @param {number|null} oldId - The ID of the previous node or null if it is the root element.
 * @returns {Object} - An object containing the new node, new parent ID, and updated graph.
 */
const prepareAccForNode = (
  key: string,
  acc: NeoGraph,
  parentId: number | null,
  oldId: number | null,
): { newNode: Node; newParentId: number; newAcc: NeoGraph } => {
  console.log( `Start Prepare Acc For Node: key=${key}, acc=${JSON.stringify(acc)}, parentId=${parentId}, oldId=${oldId}` );

  // Create a copy of the graph object
  const newAcc: NeoGraph = { n: { ...acc.n }, r: { ...acc.r } };

  let newNode: Node;

  if (oldId === null) {
    // This is the root element's iteration
    // Just create a node, do not create a relationship
    newNode = createNewNode(key);
    parentId = newNode.identity as unknown as number;
    newAcc.n[parentId] = newNode;
  } else {
    // This is an element below the root in the tree
    if (typeof parentId === 'undefined' || !newAcc.n[parentId as number]) {
      throw new Error('Parent node does not exist');
    }

    newNode = newAcc.n[parentId as number];
  }

  console.log( `End Prepare Acc For Node: result=${JSON.stringify({ newNode, newParentId: parentId, newAcc })}` );
  return { newNode, newParentId: parentId as number, newAcc };
};

/**
 * Creates an entry in the graph with the given value and key under the specified parent.
 *
 * @param {Graph} acc - The graph object.
 * @param {number} parentId - The ID of the parent node.
 * @param {any} value - The value to be added as a property.
 * @param {string} key - The key for the property.
 * @returns {Graph} - The updated graph.
 */
const createEntry = (
  acc: NeoGraph,
  parentId: number,
  value: any,
  key: string,
): NeoGraph => {
  const newAcc: NeoGraph = { n: { ...acc.n }, r: { ...acc.r } };

  if (!newAcc.n[parentId]) {
    throw new Error('Parent node does not exist');
  }

  newAcc.n[parentId].properties[key] = value;

  console.log( `Create Entry: parentId=${parentId}, value=${value}, key=${key}, newAcc=${JSON.stringify(newAcc)}` );
  return newAcc;
};

/**
 * Breaks up a node object into individual nodes and relationships in a graph.
 *
 * @param {any} obj - The node object to break up.
 * @param {number|null} parentId - The ID of the parent node (default: null for root).
 * @param {string|null} parentName - The name of the parent node (default: null for root).
 * @param {Graph} outerAcc - The outer accumulator for nodes and relationships (default: empty graph).
 * @param {number|null} oldId - The old ID of the parent node (default: null).
 * @returns {Graph} - The updated graph with new nodes and relationships.
 */
const breakUpNode = (
  obj: any,
  parentId: number | null = null,
  parentName: string | null = null,
  outerAcc: NeoGraph = { n: {}, r: {} },
  oldId: number | null = null,
): NeoGraph => {
  console.log( `Start breaking up Node: obj=${JSON.stringify(obj)}, parentId=${parentId}, outerAcc=${JSON.stringify(outerAcc)}, oldId=${oldId}` );

  const newNodes = Object.entries(obj).reduce((acc, [key, value]) => {
    const name = parentId === null ? 'root' : key;

    // Create a new node and update the accumulator
    let { newNode, newParentId, newAcc } = prepareAccForNode(
      name,
      acc,
      parentId,
      oldId,
    );

    if (oldId === null) {
      oldId = newParentId;
    }

    parentId = newParentId;

    if (typeof value !== 'undefined' && value && typeof value === 'object') {
      console.debug( `The value is: ${JSON.stringify({ tf: typeof value === 'object', value })}` );

      const veryNewNode = createNewNode(key);
      newAcc.n[veryNewNode.identity as unknown as number] = veryNewNode;

      newAcc = createRel({
        oldId: parentId as unknown as Integer,
        parentId: veryNewNode.identity,
        newAcc,
      });

      console.debug( `Breaking up the node: ${JSON.stringify({ tf: typeof value === 'object', value, vnnid: veryNewNode.identity as unknown as number, vnnls: veryNewNode.labels.join(','), newAcc, nnid: newNode.identity })}` );

      newAcc = breakUpNode(
        value,
        veryNewNode.identity as unknown as number,
        veryNewNode.labels.join(','),
        newAcc,
        newNode.identity as unknown as number,
      );
    } else {
      newAcc = createEntry(newAcc, parentId, value, key);
    }

    return newAcc;
  }, outerAcc);

  console.log( `End breaking up Node: result=${JSON.stringify({ nodes: newNodes.n, rels: newNodes.r })}, obj=${JSON.stringify(obj)}, parentId=${parentId}, outerAcc=${JSON.stringify(outerAcc)}, oldId=${oldId}` );

  return newNodes;
};

/**
 * Creates a relationship between nodes.
 * @param params An object containing oldId, parentId, and the current accumulator.
 * @returns The updated accumulator with the new relationship.
 */
const createRel = ({
  oldId,
  parentId,
  newAcc,
}: {
  oldId: Integer
  parentId: Integer
  newAcc: NeoGraph
}): NeoGraph => {
  console.log( `Start create rel: oldId=${oldId}, parentId=${parentId}, newAcc=${JSON.stringify(newAcc)}` );

  if (oldId !== parentId) {
    const relExists = Object.values(newAcc.r).some(rel => rel.end === parentId);

    if (!relExists) {
      const relationship: Relationship = {
        type: 'has_property',
        identity: Math.floor(Math.random() * 10000) as unknown as Integer,
        properties: {},
        start: oldId,
        end: parentId,
      };
      newAcc.r[relationship.identity as unknown as number] = relationship;
    }
  }

  console.log(`End create rel: result=${JSON.stringify(newAcc)}`);
  return newAcc;
};

// /**
//  * Prepares the accumulator for a node relationship in the graph.
//  *
//  * @param {string} key - The key for the node.
//  * @param {Graph} acc - The graph object.
//  * @param {number|null} parentId - The ID of the parent node or null if it is the root element.
//  * @param {number|null} oldId - The ID of the previous node or null if it is the root element.
//  * @returns {Object} - An object containing the new node, new parent ID, and updated graph.
//  */
// const prepareAccForNodeRel = (
//   key: string,
//   acc: Graph,
//   parentId: number | null,
//   oldId: number | null
// ): { newNode: Node; newParentId: number; newAcc: Graph } => {
//   const newAcc: Graph = { nodes: { ...acc.nodes }, rels: { ...acc.rels } }
//   let newNode: Node

//   if (oldId === null) {
//     // This is the root element's iteration
//     // Just create a node, do not create a relationship
//     newNode = createNewNode(key)
//     parentId = newNode.id
//     newAcc.nodes[parentId] = newNode
//   } else {
//     // This is an element below the root in the tree
//     if (typeof parentId === null || !newAcc.nodes[parentId as number]) {
//       throw new Error('Parent node does not exist')
//     }

//     newNode = newAcc.nodes[parentId as number]

//     // Do not create a relationship for the root
//     if (oldId !== parentId as number) {
//       // Check all acc relations if a relation exists where the end points to the parentId
//       const relExists = Object.values(newAcc.rels).some(
//         rel => rel.end === parentId as number
//       )

//       // Create new relationship if it doesn't exist yet
//       if (!relExists) {
//         const relationship: Relationship = {
//           labels: ['has_property'],
//           id: Math.floor(Math.random() * 10000), // Generate a random ID
//           properties: {},
//           start: oldId,
//           end: parentId as number,
//         }

//         newAcc.rels[relationship.id] = relationship
//       }
//     }
//   }

//   return { newNode, newParentId: parentId, newAcc }
// }

/**
receives for the to-be-saved graph the first failing element or true
then it either returns true or it sets the failing element to the editcard and returns false
*/
export const removeChangedFromElementAndConvertToJson = (
  nodes: cytoscape.NodeSingular[],
  edges: cytoscape.EdgeSingular[],

): { nodeJson: string[], edgeJson: string[] } => {
  console.log('Will remove Changed Flag, and convert to Json', {nodes, edges})

  // do not save the 'changed' property
  var nodeJson: string[] =
    nodes.length == 0
      ? []
      : nodes.map(x => {
        x.removeData('changed')
        return x.json()
      })
  var edgeJson =
    edges.length == 0
      ? []
      : edges.map(x => {
        x.removeData('changed')
        return x.json()
      })

  console.log('Removed Changed Flag, Converted to Json', {nodeJson, edgeJson})
  return { nodeJson, edgeJson }
}

/**
receives for the to-be-saved graph the first failing element or true
then it either returns true or it sets the failing element to the editcard and returns false
*/
export const checkAndHandleMandatoryCheckFailed = (
  mandatoryFieldsAreAllFilled: Array<any> | true,
  cy: cytoscape.Core,
  callback: Function
): boolean | Error => {
  if (mandatoryFieldsAreAllFilled === true || mandatoryFieldsAreAllFilled.length === 0) {
    console.log('there are mandatory fields are all ok', {mandatoryFieldsAreAllFilled})
    return true
  }else{
    try {
      const missing_fields = mandatoryFieldsAreAllFilled[0][0].failing
      const id_of_first_entry = mandatoryFieldsAreAllFilled[0][0].id
      const entry = cy.$(id_of_first_entry)

      console.log('there are mandatory fields are all ok not', {missing_fields, id_of_first_entry,first: mandatoryFieldsAreAllFilled[0][0]},  entry)

      callback([entry.data()]);
    } catch (error) {
      console.error('possibly element cant be found', {error})
      throw new Error('ID or Element not given')
    }
    return false
  }
}

/**
checks if all mandatory fields are filled, 
returns the first failing element or true
*/
export const mandatoryFilled = (workspace: {
  nodes: cytoscape.NodeSingular[]
  edges: cytoscape.EdgeSingular[]
}) => {
  // const collector = { nodes: [], edges: [] }

  const uiDuck = require('../ducks/uiDuck');

  const isFieldMandatoryAndMissing = (
    ed: cytoscape.NodeDataDefinition | cytoscape.EdgeDataDefinition,
    type: string,
  ) : false | string[] => {
    // only can be mandatory if there are type defaults defined
    if (
      typeof uiDuck.initialState.typeDefaults[type] !== 'undefined' &&
      uiDuck.initialState.typeDefaults[type]
    ) {
      // look up all
      const incorrectlyFilled = Object.keys(
        uiDuck.initialState.typeDefaults[type],
      )
        .filter(
          typeDefaultName =>
            uiDuck.initialState.typeDefaults[type][typeDefaultName].mandatory,
        ) // only check those types which are mandatory
        .filter(
          mandatoryDefaultName =>
            typeof ed[mandatoryDefaultName] === 'undefined' ||
            uiDuck.initialState.typeDefaults[type][mandatoryDefaultName].values
              .default === ed[mandatoryDefaultName],
        );

      // if there have been incorrectly filled fields identified, fail the decision function.
      if (incorrectlyFilled.length) {
        console.log('there are mandatory fields incorrectly filled.', { incorrectlyFilled, ed });
        return incorrectlyFilled;
      } else {
        console.log('no there are mandatory fields incorrectly filled.', { incorrectlyFilled, ed });
        return false;
      }
    } else {
      // if no type defaults are defined, it is always ok.
      return false;
    }
  };

  // checks if something mandatory isnt filled, @todo add info
  const checker = (
    element: cytoscape.SingularElementArgument,
  ): Object | false =>
    isFieldMandatoryAndMissing(element.data(), element.data().type);

  const failingElements = Object.values(workspace)
    .filter(elementsarray =>
      (
        elementsarray as (cytoscape.NodeSingular | cytoscape.EdgeSingular)[]
      ).find(element => checker(element)),
    )
    .map(elementsarray =>
      (elementsarray as (cytoscape.NodeSingular | cytoscape.EdgeSingular)[])
        .find(element => checker(element))
        ?.map(failel => ({ id: failel.id(), failing: checker(failel) })),
    );

  // Return true if no element failed
  if ( typeof failingElements === 'undefined' || !failingElements ) {
    console.log('All elements are filled', [ workspace ]);
    return true;
  }

  // console.log('here is the first fail', { fd: firstFailingElement?.data()})
  // send to meta
  console.log('there are mandatory fields Meta will be', { failingElements });
  return failingElements;
};

/**
 * Prepares the graph for writing to Neo4j by breaking up the workspace object.
 *
 * @param {Object} workspace - The workspace object.
 * @param {boolean} isArray - Indicates whether the workspace object is an array of objects (default: false).
 * @returns {string|false} - The prepared graph query or false if no operation is needed.
 */
export const prepareGraphForNeowrite = (
  workspace: Object,
  isArray = false,
): string | false => {
  const collector: NeoGraph = { n: {}, r: {} };

  if (isArray) {
    // Break up each object in the array
    Object.values(workspace).forEach(vals => {
      const brokenNodes = breakUpNode(vals);
      collector.n = { ...collector.n, ...brokenNodes.n };
      collector.r = { ...collector.r, ...brokenNodes.r };
    });
  } else {
    // Break up the provided object
    const brokenNodes = breakUpNode(workspace);
    collector.n = { ...collector.n, ...brokenNodes.n };
    collector.r = { ...collector.r, ...brokenNodes.r };
  }

  // Return false if no operation is needed
  if (
    Object.keys(collector.n).length === 0 &&
    Object.keys(collector.r).length === 0
  ) {
    return false;
  }

  const { parameters } = buildQueryFromBrokenUp(collector);
  return parameters;
};

/**
 * Builds a Neo4j query from the broken-up graph.
 *
 * @param {NeoGraph} brokenNodes - The broken-up graph.
 * @returns {parameters} - The Neo4j query.
 */
const buildQueryFromBrokenUp = (
  brokenNodes: NeoGraph,
): { parameters: string } => {
  const nodeQueryParams = Object.entries(brokenNodes.n)
    .map(([key, node], index) => {
      const properties = Object.entries(node.properties)
        // .map(([propKey, propValue]) => `$${propKey}_${index}`)
        .map(([propKey, propValue]) => `"${propKey}": "${propValue}"`)
        .join(', ');

      return `{"__identifier": "${key}", "label": "${node.labels.join(',')}", "properties": {${properties}}}`;
      // return `{"__identifier": "${key}", "label": "${node.label.join(
      //   '-'
      // )}", "properties": {${properties}}}`
    })
    .join(', ');

  const relQueryParams = Object.entries(brokenNodes.r)
    .map(
      ([key, rel], index) =>
        `{"start": "${rel.start}", "end": "${rel.end}", "properties": {}}`,
    )
    .join(', ');

  const string = `{"nodes": [${nodeQueryParams}],"rels": [${relQueryParams}]}`;
  console.log('New Graph is Cytoscape Collection from string', { string });

  const newGraph: cytoscape.Collection = JSON.parse(string);
  console.log('New Graph is Cytoscape Collection', { newGraph, a: typeof newGraph });
  const parameters = `{"nodes": [${nodeQueryParams}], "rels": [${relQueryParams}]}`;

  return { parameters };
};

/**
 * Executes a Cypher query with the given parameters.
 *
 * @param {string} cypherQuery - The Cypher query to be executed.
 * @param {object} parameters - The parameters for the Cypher query.
 */
export const runCypherQuery = (
  session: Session,
  cypherQuery: string,
  parameters: object,
) => {
  // Execute the cypherQuery with the parameters
  // Example using Neo4j JavaScript driver
  session
    .run(cypherQuery, parameters)
    .then(result => {
      // Handle the query result
    })
    .catch(error => {
      // Handle any errors
    });
};

interface ProcessedNode {
  // type: string,
  // identity: number,
  // label?: string,
  [key: string]: any
}


interface Identity64 {
  high: number; // upper 32 bits
  low: number;  // lower 32 bits
}

export const convertHighLowToNumber = (identity: Identity64): number => {
  // Convert 64-bit to number using high and low parts
  return identity.low + identity.high * 0x100000000;
}


/**
 * Reconstructs the original node by aggregating properties from broken-down components.
 * @param graph The graph containing the broken-down nodes and relationships.
 * @param startNode The starting node for reconstruction (default: undefined).
 * @returns an object with key: label of the nodeThe reconstructed node.
 */
export const reconstructNode = (
  graph: NeoGraph,
  startNode: Node | undefined = undefined,
): { [key: string]: ProcessedNode } => {
  try {
    console.log('Starting node reconstruction', { graph: JSON.stringify(graph) });

    // If no start is provided, find the root node
    if (!startNode) {
      console.log('Finding root node');
      startNode = Object.values(graph.n).find(
        node => node.properties.__label === 'root',
      );
    }

    // If the root node is not found, throw an error
    if (!startNode) {
      throw new Error('Root node not found in the graph');
    }
    console.log('Start node defined', { start: JSON.stringify(startNode) });

    const { properties, labels, identity, ...remains } = { ...startNode };
    const convertedIdentity = identity.low + identity.high * 0x100000000;

    console.log('Startnodes prop label identity', { properties, labels, identity, remains });

    // prebuild the target structure
    const processedNode: ProcessedNode = {
      ...properties,
      type: labels.join(','),
      identity: convertedIdentity,
      ...remains,
    };

    const { __label = '', ...processedNode2 } = { ...properties, ...remains };

    console.log('The mix only', { processedNode2 });

    // processedNode = processedNode2
    processedNode.label = __label;

    // Initialize the return value object to store reconstructed nodes
    const retval: { [key: string]: ProcessedNode } = {
      [__label]: processedNode2,
    };

    console.log('Startnodes Processed Node', { retval, processedNode });

    // Find linked nodes by reducing relationships and filtering based on the start
    const linkedNodes = Object.values(graph.r)
      .reduce(
        (acc: Relationship[], relArray: Relationship | Relationship[]) =>
          acc.concat(Array.isArray(relArray) ? relArray : [relArray]),
        [],
      )
      .filter(
        rel =>
          rel.properties.start === (startNode as Node).properties.__identifier,
      )
      .map(rel =>
        Object.values(graph.n).find(
          innerNode => innerNode.properties.__identifier === rel.properties.end,
        ),
      );

    console.log('Linked nodes found', { linkedNodes: JSON.stringify(linkedNodes) });

    // Recursively reconstruct and merge properties from linked nodes
    if (linkedNodes.length > 0) {
      console.log('shoud be here x', { linkedNodes });

      linkedNodes
        .map(linkedNode => reconstructNode(graph, linkedNode as Node)) // Recursively reconstruct each linked node
        .map(innerResult => {
          // this section runs, if we had a reason for recursion and an outcome
          // the outcome should already be processed, ie cleaned and merged, before it's arriving here

          console.log(' ', { innerResult, keys: Object.keys(innerResult) });
          const mergedInnerResult = Object.fromEntries(
            Object.entries(innerResult).map(([k, innerNode]) => {
              if (
                typeof innerNode.properties !== 'undefined' &&
                innerNode.properties &&
                innerNode.properties.length
              ) {
                return [k, { ...innerNode.properties }];
              } else {
                const { properties, ...rest } = innerNode;
                return [k, rest];
              }
            }),
          );
          console.log('shoud be here', { innerResult, mergedInnerResult });
          const label = (startNode as Node).properties.__label as string;

          if (
            typeof innerResult.properties !== 'undefined' &&
            innerResult.properties
          ) {
            retval[label] = {
              ...retval[label],
              ...innerResult.properties,
            };
            console.log('Just added this', { retval: JSON.stringify(retval[label]) });
          } else {
            const { properties, ...rest } = innerResult;
            retval[label] = {
              ...retval[label],
              ...rest,
            };
            console.log('Just added this', { retval: JSON.stringify(retval[label]) });
          }
        });
    }

    const foo = retval[__label];
    const { __identifier, ...restWithoutIdentifier } = foo;
    console.log('Reconstructed node properties fool', { restWithoutIdentifier, fool: JSON.stringify(restWithoutIdentifier) });

    retval[__label] = restWithoutIdentifier;
    console.log('Reconstructed node properties', { retval: JSON.stringify(retval) });
    return retval;
  } catch (error) {
    console.error('Error reconstructing node', { error });
    throw error;
  }
};
