// Kluski!
import backendReducer, {
  InitialState as BackendType,
  initialState as backendState,
} from './ducks/backendDuck';
import uiReducer, {
  InitialState as UiType,
  initialState as uiState,
} from './ducks/uiDuck';
import userReducer, {
  InitialState as UserType,
  initialState as userState,
} from './ducks/userDuck';
import timeReducer, {
  InitialState as TimeType,
  initialState as timeState,
} from './ducks/timeDuck';

// Define the root state interface
export interface RootState {
  backendApp: BackendType
  uiApp: UiType
  userApp: UserType
  timeApp: TimeType
}

export const rootState: RootState = {
  backendApp: backendState,
  uiApp: uiState,
  userApp: userState,
  timeApp: timeState,
};
