// nextauth
import { SessionProvider } from 'next-auth/react';
import { configureStore } from '@reduxjs/toolkit';

import type { AppProps } from 'next/app';
// import { Container } from 'next/app';
import './styles.css';
import { useSession } from 'next-auth/react';

// MaterialUi & JSS
import { JssProvider } from 'react-jss';

// redux
import React, { useState, useEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { applyMiddleware } from 'redux';

// epics - async actions, rerouting actions in Ducks
import { createEpicMiddleware } from 'redux-observable';

// import getPageContext from '../lib/getPageContext';
import { CacheProvider } from '@emotion/react'; // For caching styles
import createCache from '@emotion/cache';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Root Elements - they combine all used elements
import { rootEpic } from '../shared/rootEpic';

import { rootState } from '../shared/rootState';
import { setupStore } from '../shared/store';
import immutableStateInvariant from 'redux-immutable-state-invariant';

/* Middleware */
// Preparing Epic Middleware for Redux Store
const epicMiddleware = createEpicMiddleware();

// writes logs of each action for debugging
const traceMiddleware = (store: any) => (next: any) => (action: any) => {
  console.trace(`Action dispatched: ${action.type}`);
  return next(action);
};

// const immu = require('redux-immutable-state-invariant')
// const ii = immu.immutableStateInvariant({ignore: ['uiApp.structure']})
const middleware =
  process.env.NODE_ENV === 'production' ? [epicMiddleware] : [epicMiddleware];
// [immutableStateInvariant({ignore: ['uiApp.meta','uiApp.structure', 'uiApp.theleft','backendApp.theleft','backendApp.data']}), traceMiddleware, epicMiddleware]
// const middleware = [epicMiddleware];
// @ts-ignore
const store = setupStore(rootState, middleware);
/* Redux-Store */

// Inject Epics into EpicMiddleware
epicMiddleware.run(rootEpic);

// Operational URL
const url = 'http://localhost:3000';
const { searchParams } = new URL(url);

// Desktop/Relate params
const relateUrl = searchParams.get('relateUrl');
const relateApiToken = searchParams.get('relateApiToken');
const relateProjectId =
  searchParams.get('relateProjectId') ||
  searchParams.get('neo4jDesktopProjectId');
const neo4jDesktopGraphAppId = searchParams.get('neo4jDesktopGraphAppId');

// define environment
// const env = detectRuntimeEnv(window, NEO4J_CLOUD_DOMAINS)
const env = 'dev';
const app = false;

// Initial App Store Settings
// store.dispatch({
//  app,
//  url,
//  env,
//  relateUrl,
//  relateApiToken,
//  relateProjectId,
//  neo4jDesktopGraphAppId
// })

/*
Component Block
*/
// Wrapper to enforce Authentication or allow in case Authentication is explicitly disabled
function Auth({ children }: { children: any }) {
  try {
    const { status } = useSession({ required: true });
    const loading = status === 'loading';

    // When rendering client side don't display anything until loading is complete
    const [ret, setRet] = useState(null);
    useEffect(() => {
      if (loading) {
        setRet(null);
      } else {
        setRet(children);
      }
    });

    return ret;
  } catch (e) {
    // check if Authentication is explicitly disabled
    console.log('children', children);
    if (typeof children.auth !== 'undefined' && children.auth === false) {
      return children;
    }
    throw 'Authentication Required'; // generates an exception
  }
}

const theme = createTheme({
  // your theme settings
});

function App({ Component, pageProps }: AppProps): JSX.Element {
  // const pageContext = getPageContext();
  // console.log('blabla', pageContext);
  return (
    <ReduxProvider store={store as any}>
      <SessionProvider
        // Provider options are not required but can be useful in situations where
        // you have a short session maxAge time. Shown here with default values.
        session={pageProps.session}
      >
        <Auth>
          <ThemeProvider theme={theme}>
            <Component {...pageProps} store={store as any} />
          </ThemeProvider>
        </Auth>
      </SessionProvider>
    </ReduxProvider>
  );
}
export default App;
