// @ts-nocheck
// @ts-ignore
import { combineReducers } from 'redux';
// import { combineReducers } from '@reduxjs/toolkit'

// Kluski!
// Here we need the Reducers (default exports) from the Ducks

import backendApp from './ducks/backendDuck';
import uiApp from './ducks/uiDuck';
import userApp from './ducks/userDuck';
import timeApp from './ducks/timeDuck';

export const rootReducer = combineReducers({
  backendApp,
  uiApp,
  userApp,
  timeApp,
});
